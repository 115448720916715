/**
  * multiple-select - Multiple select is a jQuery plugin to select multiple elements with checkboxes :).
  *
  * @version v1.5.2
  * @homepage http://multiple-select.wenzhixin.net.cn
  * @author wenzhixin <wenzhixin2010@gmail.com> (http://wenzhixin.net.cn/)
  * @license MIT
  */

.ms-offscreen {
	clip: rect(0 0 0 0) !important;
	width: 1px !important;
	height: 1px !important;
	border: 0 !important;
	margin: 0 !important;
	padding: 0 !important;
	overflow: hidden !important;
	position: absolute !important;
	outline: 0 !important;
	left: auto !important;
	top: auto !important
}

.ms-parent {
	display: block;
	position: relative;
	vertical-align: middle;
	width: 100% !important;
	max-width: 100%;
	font-size: 100%
}

.ms-choice {
color: $text-color;
	height: 5rem;
	padding: 1rem 3.5rem 1rem 1.8rem;
	font-weight:400;
	line-height: 120%;
	position: relative;
border: 1px solid #D1D1D1;
background: #FFF;
border-radius: .4rem;
width: 100%;
text-align: left;
text-transform: uppercase;
}
.selected {
	.ms-choice {
		background: $color1
	}
	.ms-choice > div.icon-caret {
		color: $text-color
	}
}
.ms-choice.disabled {
	background-color: #f4f4f4;
	background-image: none;
	border: 1px solid #ddd;
	cursor: default
}

.ms-choice>span {
/*	position: absolute;
	top: 50%;
	left: 0;
	right: 20px;
	transform: translateY(-50%);*/
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	//padding-left: 8px;
	font-weight: 700;
}

.ms-choice>span.placeholder {
	color: $color-active;
}

.ms-choice>div.icon-close {
	position: absolute;
	top: 0;
	right: 1.6rem;
	height: 100%;
	width: 1.6rem
}

.ms-choice>div.icon-close:before {
	content: '×';
	color: #888;
	font-weight: bold;
	position: absolute;
	top: 50%;
	margin-top: -1.4rem
}

.ms-choice>div.icon-close:hover:before {
	color: #333
}

.ms-choice>div.icon-caret {
		color: $color-active;
	position: absolute;
	top: 50%;
	right: 1.4rem;
	margin-top: -.4rem;
	font-size: .8rem;
	&:before {
		@extend .icon-caret-menu;
	}
}

.ms-choice>div.icon-caret.open {
	border-color: transparent transparent #888 transparent;
	border-width: 0 .4rem .5rem .4rem
}

.ms-drop {
	width: auto;
	min-width: 100%;
	overflow: hidden;
	display: none;
	margin-top: -1px;
	padding: 0;
	position: absolute;
	z-index: 1000;
	background: #fff;
	color: #000;
	border: 1px solid #aaa;
	border-radius: .4rem
}

.ms-drop.bottom {
	top: 100%;
	box-shadow: 0 .4rem .5rem rgba(0, 0, 0, 0.15)
}

.ms-drop.top {
	bottom: 100%;
	box-shadow: 0 -.4rem .5rem rgba(0, 0, 0, 0.15)
}

.ms-search {
	display: inline-block;
	margin: 0;
	min-height: 2.6rem;
	padding: .2rem;
	position: relative;
	white-space: nowrap;
	width: 100%;
	z-index: 10000;
	box-sizing: border-box
}
.placeholder-psevdo {
white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    font-weight: 700;
    color: $color-active;
	font-size: 2rem
}
.ms-choice > span {
	position: absolute;
	opacity: 0;
	left: 0;
}
.selected .placeholder-psevdo {
	color: $text-color;
}
.ms-search input {
	width: 100%;
	height: auto !important;
	min-height: 2.4rem;
	padding: 0 .5rem;
	margin: 0;
	outline: 0;
	font-family: sans-serif;
	border: 1px solid #aaa;
	border-radius: .5rem;
	box-shadow: none
}

.ms-drop ul {
	overflow: auto;
	margin: 0;
	padding: 0
}

.ms-drop ul>li {
	list-style: none;
	display: list-item;
	background-image: none;
	position: static;
	padding: 1.2rem 1.8rem
}

.ms-drop ul>li .disabled {
	font-weight: normal !important;
	opacity: .35;
	filter: Alpha(Opacity=35);
	cursor: default
}

.ms-drop ul>li.multiple {
	display: block;
	float: left
}

.ms-drop ul>li.group {
	clear: both
}

.ms-drop ul>li.multiple label {
	width: 100%;
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis
}

.ms-drop ul>li label {
	position: relative;
	margin-bottom: 0;
	font-weight: normal;
	display: block;
	white-space: nowrap;
	cursor: pointer
}

.ms-drop ul>li label.optgroup {
	font-weight: bold
}

.ms-drop ul>li.hide-radio {
	padding: 0
}

.ms-drop ul>li.hide-radio:focus,
.ms-drop ul>li.hide-radio:hover {
	background-color: #f8f9fa
}

.ms-drop ul>li.hide-radio.selected {
	color: $color2;
}

.ms-drop ul>li.hide-radio label {
	margin-bottom: 0;
	padding: .5rem 8px
}

.ms-drop ul>li.hide-radio input {
	display: none
}
.placeholder-option {
	display: none;
}
.ms-drop ul>li.option-level-1 label {
	padding-left: 28px
}

.ms-drop input[type="radio"],
.ms-drop input[type="checkbox"] {
	position: absolute;
	margin-top: .3rem;
	margin-left: -1.25rem
}

.ms-drop .ms-no-results {
	display: none
}

[multiple] ~.ms-parent  .ms-drop ul > li span {
    padding-left: 3rem;
    font-size: 1.6rem;
    line-height: 1.2;
    width: 100%;
}
[multiple]~.ms-parent .ms-drop ul > li span:before{
    content: '';
    display: block;
    position: absolute;
    left:.4rem;
    height: 1.6rem;
    width: 1.6rem;
    top: .6rem;
    opacity: 0;
    background: url(../img/check.svg) 50% 50% no-repeat;
    background-size: contain;
}
.btn-apply {
	margin-top: 10px;
	font-size: 1.8rem;
font-weight: 600;
width: 100%;
border-radius: .4rem
}
[multiple] ~.ms-parent .ms-drop ul > li label span:after {
    content: '';
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    left: 0;
    top: 0.2rem;
    border: 1px solid $text-color;
    border-radius: .2rem;
    position: absolute;
    z-index: 0;
}

[multiple] ~.ms-parent .ms-drop ul > li.selected span:before {
    opacity: 1;
    color: #333;
}

[multiple] ~.ms-parent .ms-drop ul > li:hover,[multiple] ~.ms-parent .ms-drop ul > li.selected {
    color: $color-active;
}


@media screen and (max-width: 767px) {
	[multiple] ~.ms-parent  .ms-drop ul > li span {
	    font-size: 1.4rem;
	}
	[multiple] ~ .ms-parent .ms-drop ul > li label span:after {

	    width: 2rem;
	    height: 2rem;
	    top: 0;
	}
[multiple] ~ .ms-parent .ms-drop ul > li span:before {
    left: .3rem;
    height: 1.4rem;
    width: 1.4rem;
    top: .3rem;
    }
    .ms-choice {
    font-size: 1.6rem;
    height: auto;
    padding: 11px 3.5rem 10px 1.8rem;
    }
    .placeholder-psevdo {
    font-size: 1.6rem;
    white-space: normal;
    overflow: visible;
    line-height: 1.2;
    }
.ms-choice > div.icon-caret {
    right: 2.5rem;
    margin-top: -.3rem;
    font-size: .6rem;
}
.ms-choice > div.icon-caret {
        right: 1.5rem;
        }
}
