@font-face {
    font-family: 'Noto Sans';
    src: url('../fonts/NotoSans-Regular.eot');
    src: url('../fonts/NotoSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NotoSans-Regular.woff2') format('woff2'),
    url('../fonts/NotoSans-Regular.woff') format('woff'),
    url('../fonts/NotoSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Noto Sans';
    src: url('../fonts/NotoSans-Italic.eot');
    src: url('../fonts/NotoSans-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NotoSans-Italic.woff2') format('woff2'),
    url('../fonts/NotoSans-Italic.woff') format('woff'),
    url('../fonts/NotoSans-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('../fonts/NotoSans-Bold.eot');
    src: url('../fonts/NotoSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NotoSans-Bold.woff2') format('woff2'),
    url('../fonts/NotoSans-Bold.woff') format('woff'),
    url('../fonts/NotoSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('../fonts/NotoSans-BoldItalic.eot');
    src: url('../fonts/NotoSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NotoSans-BoldItalic.woff2') format('woff2'),
    url('../fonts/NotoSans-BoldItalic.woff') format('woff'),
    url('../fonts/NotoSans-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Noto Sans';
    src: url('../fonts/NotoSans-SemiBold.eot');
    src: url('../fonts/NotoSans-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NotoSans-SemiBold.woff2') format('woff2'),
    url('../fonts/NotoSans-SemiBold.woff') format('woff'),
    url('../fonts/NotoSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tenor Sans';
    src: url('../fonts/TenorSans-Regular.eot');
    src: url('../fonts/TenorSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TenorSans-Regular.woff2') format('woff2'),
    url('../fonts/TenorSans-Regular.woff') format('woff'),
    url('../fonts/TenorSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  font-display: block;
  src:  url('../fonts/icomoon/fonts/icomoon.eot?ihyadm');
  src:  url('../fonts/icomoon/fonts/icomoon.eot?ihyadm#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/fonts/icomoon.ttf?ihyadm') format('truetype'),
    url('../fonts/icomoon/fonts/icomoon.woff?ihyadm') format('woff'),
    url('../fonts/icomoon/fonts/icomoon.svg?ihyadm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

