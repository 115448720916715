.section-top {
    padding-top: 20rem;
}

.main-section {
    position: relative;
    .wrapper {
        z-index: 1;
        position: static;
    }
}
.decor-main {
    top: 10rem;
    left: 0;
    width: 19vw;
    max-width: 364px;
        position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 0;
        z-index: 1;
    &:before {
        content: '';
        display: block;
        padding-top: 133%;
    }
}
.decor--1 {
    bottom: 0;
    right: 5.4rem;
    transform: translateY(78%);
    width: 19.5%;
    max-width: 374px;
    background-size: contain;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 0;

    &:before {
        content: '';
        display: block;
        padding-top: 92%
    }
}

.main-block {
    display: flex;
    align-items: center;
    &__head {
        position: relative;
        z-index: 1;
        width: 57%;
        padding-right: 20.2%;
        padding-bottom: 4.1rem
    }

    &__slider {
    width: 43%;
        background-position: 0 100%;
        position: relative;
        background-repeat: no-repeat;
        z-index: 0;
        &:before {
            content: '';
            display: block;
            position: absolute;
            right: 100%;
            top: 0;
            bottom: 0;
            z-index: 1;
            background: #fff;
            width: 10000px;
        }
    }

    &__download {
        margin-top: 5rem;
        div +  div {
          margin-top: 1.8rem      
        }
    }
}

.subtitle-main {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
}

.section-text {
    line-height: 140%;
    margin-top: 1rem
}

.section-main-catalog {
    padding: 7rem 0 7rem
}

.h1-main {
    margin-bottom: 2.6rem;
    font-size: 5.2rem;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;

    span {
        color: $color-active;
    }
}

.main-slider {
    position: relative;
    z-index: 0;
    .slick-list {
        margin: 0 -1.2rem;
        overflow: visible;
    }

    &__list {
        height: 44.5rem;
        transition: opacity 1s ease, transform 1s ease;
        transform: translateY(0);
        opacity: 1;
        &:not(.slick-initialized) {
            display: flex;
            opacity: 0;
            transform: translateY(1rem);
        }
    }

    &__item {
        padding: 12rem 2rem 2.2rem;
        display: flex !important;
        flex-direction: column;
        justify-content: flex-end;
        border-radius: .8rem;
        background: #fff;
        overflow: hidden;
        position: relative;
        height: 35.6rem;
        color: $text-color;
         width: 100%;

        transition: all .8s ease
    }

    &__title {
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        display: -webkit-box;
        text-transform: uppercase;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: $color-active;
    }

    &__img {
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 0;

        img {
            vertical-align: top;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            transition: all .8s ease
        }
    }

    .slick-slide {
        display: flex;
        flex-direction: column;
        height: 44.5rem;
        padding: 3.5rem 1.2rem 0;
       
        max-width: 37.8rem;
        width: 37.8rem;
        transition: padding-top .8s ease;

        &>* {
            display: flex;
            flex-direction: column;
        }
    }

    .slick-current {
        padding-top: 1px;

        .main-slider__item {
            height: 63rem;
        }

        .main-slider__title {
            font-size: 2rem;
        }

        .main-slider__status {
            font-size: 1.8rem
        }

        .main-slider__description {
            min-height:  19.8rem;
            padding: 1.6rem 1.8rem 1.5rem
        }
        .main-slider__link {
            font-size: 1.8rem;
        padding: 1.2rem 2.4rem;
        }
    }



    &__description {
        position: relative;
        z-index: 1;
        background: #fff;
        padding: 1.2rem 1.2rem 1.5rem;
        min-height: 14.5rem;
        border-radius: .4rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__status {
        margin-top: .8rem;
        font-size: 1.4rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        line-height: 1.2;
    }

    &__link {
        margin-top: 1.3rem;
        font-size: 1.4rem;
        padding: .9rem 2rem;
        display: inline-flex;
        align-items: center;
        border-radius: 10rem;
        background: #fff;
        box-shadow: 0px .2rem .4rem 0px rgba(0, 0, 0, 0.12), 0px .4rem .4rem 0px rgba(24, 39, 75, 0.08);
        transition: background .8s ease;

        i {
            margin-left: 2.7rem;
            font-size: 125%;
            transform: rotate(-45deg);
            transition: transform .8s ease
        }

        &:hover {
            background: $color1;

            i {
                transform: rotate(0deg);
            }
        }
    }

    .slick-arrow {
        flex-shrink: 0;
        width: 3.2rem;
        height: 3.2rem
    }

    .slick-prev {
        order: 1;
    }

    .slick-next {
        order: 2;
        margin-right: 2rem;
    }

    .navs-slider {
        width: 46rem;
        margin-top: 6.5rem
    }

    .dots-slider {
        margin: 0 0 0 auto;
        display: flex;
        font-size: 4.8rem;
        order: 3;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
        align-items: flex-end;
        color: $color-active;
        width: 100%;

        &:before {
            content: '';
            display: block;
            height: 1px;
            width: 100%;
            background: $dark-bg;
            margin-right: 2rem;
        }

        span {
            display: block;
            line-height: 1
        }
    }
}

.section-main-categories {
    padding: 7.7rem 20.9% 18rem;
    position: relative;

}

.decor-2 {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(26%, -49%);
    width: 34%;
    max-width: 480px;
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: contain;
    z-index: 0;

    &:before {
        content: '';
        display: block;
        padding-top: 100%
    }
}

.decor-10 {
    position: absolute;
    top: 13.4rem;
    left: 3.8rem;
    -webkit-transform: translateY(-10rem);
    width: 25%;
    max-width: 65.2rem;
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: contain;
    z-index: 0;

    &:before {
        content: '';
        display: block;
        padding-top: 100%
    }
}

.decor-11 {
    position: absolute;
bottom: -5rem;
    left: 18%;
    width: 14rem;
    transform: translateX(-100%);
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: contain;
    z-index: 0;

    &:before {
        content: '';
        display: block;
        padding-top: 100%
    }
}

.main-categories {
    margin: 0 -.4rem;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &__item {
        width: calc(33.333% - .8rem);
        margin: .4rem;
        position: relative;
        overflow: hidden;
        border-radius: .8rem;
        z-index: 1;

        &:hover {
            .main-categories__img {
                img {
                    transform: scale(1.2);
                    filter: blur(1px);
                    opacity: .9;
                    width: 100%
                }
            }

            .main-categories__link {
                transform: translateY(0);
            }

            .main-categories__title {
                transform: translateY(-8.5rem);
            }
        }
    }
    &__img {
        position: relative;
        z-index: 0;
        overflow: hidden;
        overflow: hidden;
        background: $dark-bg;

        img {
            vertical-align: top;
            flex: 1 1 100%;
            transition: transform 1s ease, filter 1s ease, opacity 1s ease;
            object-fit: cover;
            object-position: 50% 50%;
            width: 100%;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(0deg, rgba(31, 31, 31, 0.50) 0%, rgba(0, 0, 0, 0.00) 35.15%);
        }
    }

    &__link {
        padding: .2rem 1rem;
        font-size: 1.8rem;
        height: 8.5rem;
        color: $color-active;
        z-index: 1;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: $dark-bg;
        transform: translateY(100%);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: transform 1s ease
    }

    &__title {
        font-size: 3.2rem;
        font-weight: 700;
        line-height: 120%;
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        color: #fff;
        padding: 0 2rem;
        bottom: 2.5rem;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: transform 1s ease;
        text-transform: uppercase;

    }

    &__all {
        padding-left: 2.5rem;
        width: calc(66.6663% - .4rem);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        position: relative;
        z-index: 1;
        text-align: right;
        .h2 {
            margin-bottom: 0
        }
        .btn {
            margin-top: 3.8rem;
        }
    }
}

    .main-categories__item + .main-categories__item + .main-categories__item + .main-categories__item +.main-categories__item ~ .main-categories__all {
            width: calc(100% - .8rem);
            padding-left: 0;
    margin-top: 27px;
    }
@media screen and (max-width: 1440px) {
    .main-block__head {
        padding-right: 5rem;
    }
}
@media screen and (max-width: 1439px) {
    .main-slider {
        margin-right: -5rem
    }
    .section-main-categories {
    padding: 7.7rem 16.5%;
    }
}


@media screen and (max-width: 1023px) {
    .h1-main {
        margin-bottom: 2.2rem;
        font-size: 4.2rem;
    }

    .main-categories__title {
        font-size: 2.5rem;
    }

    .main-slider {
        margin-right: -3rem;

        &__list {
            height: 49rem
        }
    }

    .main-slider__title,
    .main-slider .slick-current .main-slider__title {
        font-size: 1.6rem
    }

    .main-slider .navs-slider {
        width: 33rem;
    }
}

@media screen and (max-width: 767px) {
    .section-top {
        padding-top: 10.2rem;
    }
    .decor-main {
        top: 6rem;
        width: 37vw;
    }
    .main-block__slider {
        overflow: visible;
        margin-right: 0;
        &:before {
            display: none;
        }
    }
    .main-slider {
        margin-right: -2rem;
        margin-left: -2rem;
        padding-left: 2rem;
        &__list {
            height: 34.8rem
        }
        .slick-list {
            margin: 0 -.6rem
        }
    }

    .main-section {
        background-size: 39vw auto;
        background-position: 0 6.4rem;
    }

    .subtitle-main {
        font-size: 1.4rem;
        margin-bottom: .5rem
    }

    .h1-main {
        font-size: 2.4rem;
        margin-bottom: .7rem;
    }

    .section-text {
        margin-top: .7rem;
    }

    .main-block {
        display: block;

        &__head {
            width: 100%;
            padding: 0;
        }

        &__download {
            display: none;
        }

        &__slider {
            margin-top: 1.6rem;
            width: 100%;
        }
    }

    .main-slider__item {
        height: 30.8rem;
    }

    .main-slider .slick-slide {
        width: 24.8rem;
        padding-left: .6rem;
        padding-right: 0.6rem;
    }
    .main-slider .slick-slide,
    .main-slider .slick-current {
        width: 24.8rem;
        .main-slider__item {
           width: 100%;
           max-width: 100%; 
        }
    }
    .main-slider .slick-slide,
    .main-slider .slick-current .main-slider__item {
        height: 34.8rem;
    }

    .main-slider__title,
    .main-slider .slick-current .main-slider__title,
    .main-slider__status,
    .main-slider .slick-current .main-slider__status {
        font-size: 1.4rem;
    }

    .main-slider__link {
        margin-top: 1.5rem;
        font-size: 1.2rem;
        padding: .7rem 1.5rem;

        i {
            margin-left: 2.2rem;
        }
    }

    .main-slider__item {
        padding: 2rem 2rem 2rem;
    }

    .main-slider__description,
    .main-slider .slick-current .main-slider__description {
        padding: 1.2rem;
        min-height: 20.6rem;
    }

    .main-slider .slick-slide {
        padding-top: 1.9rem;
    }

    .main-slider .slick-current {
        padding-top: 0
    }

    .main-categories {
        margin: 0 -0.5rem;
    }

    .main-categories__item {
        width: calc(50% - 1rem);
        margin: 0.5rem;
        order: 1;
    }

    .main-categories__title {
        font-size: 1.4rem;
        bottom: 1.6rem
    }

    .main-categories__all {
        margin: 0 10% 1.2rem .5rem;
        display: block;
        order: 0;
        width: auto;
        flex: 1 1 100%;
        padding-left: 0;
        .h2 {
            font-size: 1.8rem;
            margin-bottom: .9rem;
            text-align: left;
            width: 100%
        }
        .btn-download {
        margin-top: 0.2rem;
            width: 100%
        }
    }
.main-categories {

    &__item {
        &:hover {
            .main-categories__img {
                img {
                    transform: scale(1.2);
                    filter: blur(1px);
                    opacity: .9;
                    width: 100%
                }
            }

            .main-categories__link {
                transform: translateY(100%);
            }

            .main-categories__title {
                transform: translateY(0);
            }
        }
    }
}
    .main-slider .navs-slider {
        display: none;
    }

    .main-slider__status {
        margin-top: .5rem;
    }

    .section-main-categories {
        padding: 2.7rem 0;
    }

    .btn-download {
        padding: 0 2rem 0 .9rem
    }
    .main-slider .slick-current .main-slider__link {
    font-size: 1.2rem;
    padding: 1rem 2rem;
}
}


@media screen and (min-width: 1024px) and (max-height: 836px) {
    .main-block__head {
        padding-right: 17.2%;
    }


    .h1-main {
        font-size: 5rem
    }

    .main-section {
        padding-top: 18rem
    }
}

@media screen and (min-width: 1366px) and (max-height: 936px) {
    .main-block__head {
        padding-right: 17.2%;
    }

    .main-section {
        padding-top: 17rem
    }

    .h1-main {
        font-size: 4.9rem
    }



    .section-main-categories {
        padding-top: 5.7rem
    }

    .main-categories__img {
        img {
            max-height: 45vh
        }
    }
}

@media screen and (min-width: 1501px) and (max-height: 936px) {


    .section-main-categories {
        padding-top: 7.7rem
    }
}
@media screen and (min-width: 1921px) {
    .main-slider {
    margin-right: calc(960px - 50vw);
}
.decor-main {
    display: none;
}
}