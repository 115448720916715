.icon-arrow-right:before {
  content: "\e900";
}
.icon-caret:before {
  content: "\e901";
}
.icon-caret-menu:before {
  content: "\e902";
}
.icon-clock:before {
  content: "\e903";
}
.icon-close:before {
  content: "\e904";
}
.icon-close-2:before {
  content: "\e905";
}
.icon-download:before {
  content: "\e906";
}
.icon-filter:before {
  content: "\e907";
}
.icon-global:before {
  content: "\e908";
}
.icon-home:before {
  content: "\e909";
}
.icon-load:before {
  content: "\e90a";
}
.icon-mail:before {
  content: "\e90b";
}
.icon-map:before {
  content: "\e90c";
}
.icon-more:before {
  content: "\e90d";
}
.icon-next:before {
  content: "\e90e";
}
.icon-phone:before {
  content: "\e90f";
}
.icon-pin:before {
  content: "\e910";
}
.icon-play:before {
  content: "\e911";
}
.icon-prev:before {
  content: "\e912";
}
.icon-quote:before {
  content: "\e913";
}
.icon-quote-2:before {
  content: "\e914";
}
.icon-search:before {
  content: "\e915";
}
