/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
     -ms-touch-action: pan-y;
     touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        vertical-align: top
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow {
    background: transparent;
    z-index: 10;
    transition: color .5s ease,border-color .5s ease;

width: 5.6rem;
height: 5.6rem;
padding: 0;
font-size: 10px;
border: 1px solid $color-active;
border-radius: 50%;

    color: $color-active;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0

}
.slick-disabled, .slick-disabled:hover {
    opacity: .5;
    cursor: default;
}
.web .slick-arrow:hover {
    color: $color-active;
    border-color: $color-active;
    position: relative;
    z-index: 1
}


.slick-next {
    margin-left: 2.6rem;

}



.slick-arrow.slick-hidden {
    display: none;
}
.slick-dots {
        text-align: center;
    list-style: none;
    margin: 66px 100px 0;
    li {
        display: inline-block;

        margin: 0 10px;
        padding: 0;
        cursor: pointer;
    }
    button {
        text-indent: -99999px;
        font-size: 0;
        padding: 0;
        background: #D1D1D1;
        width: 2.4rem;
        height: 2.4rem;
        transform: rotate(45deg);
        border-radius: .4rem;
        vertical-align: top;
        border: 0;
        transform: rotate(-45deg); 
        transition:  background .6s ease    
    }
    .slick-active {
        cursor: pointer;
            button {
            background: $color1
            }

    }
            button:hover {
            background: $color-active
            }

} 




@media screen and (max-width:767px) {
    .slick-arrow {
        text-align: center;
        font-size: 1.2rem;
        height: 4rem;
        width: 4rem;
    }

    .slick-dots {
        li {
            margin: 0 5px;
        }

        button {
            width: 1.2rem;
            height: 1.2rem;
        }
    }

    .slick-next {
        margin-left: 1.2rem;
    }
}