.header {
    z-index: 10;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 10001;
    flex-shrink: 0;
    background: #fff;

    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:after {
            display: none;
        }
    }

    &.fixed {
        .header-top {
            display: none;
        }

        .nav-logo {
            padding: .5rem 0 .5rem;

            img {
                width: 12.2rem;
                vertical-align: top;
            }
        }
    }

}


.nav-logo {
    padding: 0 0 .5rem;
    flex-shrink: 0;
    margin-right: 2rem;
    img {
    width: 22.4rem
    }
}

.header-contacts-link {
    margin-right: 2rem;
    font-size: 1.8rem;
    font-weight: 600;
    padding: 1.2rem 2.4rem 1.2rem 1.6rem;
    color: #fff;
    background: $color-active;
    border-radius: 10rem;
    display: inline-flex;
    align-items:
        center;

    i {
        background: $color2;
        border-radius: 50%;
        margin-right: 1rem;
        width: 3.2rem;
        height: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


.bars-mob {
    width: 3.4rem;
    display: block;
    cursor: pointer;
    position: relative;

    .hamburger {
        position: absolute;
        height: 2.4rem;
        top: 50%;
        left: 50%;
        margin-left: .4rem;
        width: 2.4rem;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        span {
            display: block;
            width: 100%;
            height: 1px;
            position: relative;
            background: $color2;
            top: 0;
            left: 0;
            margin: 0 0 .7rem;

            transition: .25s ease-in-out;

            &:nth-child(1) {
                // transition-delay:.5s;
            }

            &:nth-child(2) {
                transition-delay: .625s;
                margin-left: auto;
                ;
                margin-right: auto;
                width: 80%
            }

            &:nth-child(3) {
                transition-delay: .75s;
                margin-bottom: 0
            }
        }
    }

}

.bars-mob .cross {
    position: absolute;
    height: 3.6rem;
    width: 3.6rem;
    margin: -1.6rem 0 0 0;
    transform-origin: center center;
    transform: rotate(45deg);
}

.bars-mob .cross span {
    display: block;
    background: $color2;
    transition: .25s ease-in-out;
}

.bars-mob .cross span:nth-child(1) {
    height: 0%;
    width: 2px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition-delay: 0s;
}

.bars-mob .cross span:nth-child(2) {
    width: 0%;
    height: 2px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition-delay: .25s;
}

.bars-mob.active .hamburger span {
    width: 0%;
}

.bars-mob.active .hamburger span:nth-child(1) {
    transition-delay: 0s;
}

.bars-mob.active .hamburger span:nth-child(2) {
    transition-delay: .125s;
}

.bars-mob.active .hamburger span:nth-child(3) {
    transition-delay: .25s;
}

.bars-mob.active .cross span:nth-child(1) {
    height: 100%;
    transition-delay: .625s;
}

.bars-mob.active .cross span:nth-child(2) {
    width: 100%;
    transition-delay: .375s;
}

.language-currency {
    margin-left: 7rem;
    position: relative;
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
}
.global-links {
    margin-left: 3.7rem
}
.current-language {
    display: flex;
    cursor: pointer;
    align-items: center;
    position: relative;
    padding: 0.2rem 0;

    i {
        font-size: 43%;
        margin-left: 1rem;
    }

}


.language-currency {
    ul {
        padding: 0.4rem 3.1rem 2rem;
        list-style: none;
        position: absolute;
        left: 50%;
        top: 100%;
        width: 100%;
        margin-top: -1px;
        width: max-content;
        opacity: 0;
        color: #fff;
        pointer-events: none;
        background: $color-active;
        transform: translate(-50%, 1rem);
        transition: transform .5s ease, opacity .5s ease;
    }

    li {
        margin-top: 1rem;
    }

    a {
        color: inherit;
        border-bottom: 1px solid;
        transition: border-color .6s ease 0s
    }

    a:hover {
        border-color: transparent;
    }

    &:hover ul {
        opacity: 1;
        transform: translate(-50%, 0);
        pointer-events: auto;
    }

    .curent {
        display: none;
    }
}


.open-header {
    .bars-mob {
        z-index: 101
    }

    .nav-logo {
        z-index: 101;
    }
}


.search-link {
    margin-left: 3.2rem;
    background: $color2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 3.2rem;
    height: 3.2rem;
    color: #fff;
    font-size: 1.6rem;
    position: relative;
    z-index: 1
}

.header-top {
    padding: 1.5rem 0 .9rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 1
}

.header-bottom {
    padding-top: .9rem;
    padding-bottom: .9rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #F7F7F7;
    position: relative;
    z-index: 0
}

.header-socials {
    margin-right: 5.2rem;
}

.menu-box {
    display: flex;
    align-items: center;
}

.main-menu {
    display: flex;
    align-items: center;
    list-style: none;
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;

    i {
        font-size: 36%;
        margin-left: .7rem;
    }

    &>li {
        margin-left: 3.4rem;
        position: relative;

        &.current-menu-item>a {
            color: $color-active;
        }
        & > a {
            display: flex;
            align-items: center;
        }
    }

    a {
        color: inherit;
    }

    .menu-dropdown {
        text-transform: none;
        font-weight: 400
    }
}

.menu-dropdown {
    display: none;
    position: absolute;
    left: 0;
    margin-left: -2.5rem;
    top: 100%;
    min-width: max-content;
    text-align: left;
    box-shadow: 0px 4px 15px 0px #00000026;

}

.menu-dropdown-list {
    padding: .9rem 0;
    background: #fff;
    display: flex;
    border-radius: .4rem;
}

.menu-dropdown ul {
    padding-top: 0.4rem;
    display: block;
    list-style: none;
}

.menu-dropdown ul li {
    padding: .9rem 2.3rem;
    text-align: left;
    margin: 0;
}

.menu-dropdown ul a {
    font-size: 1.8rem;
    color: #000;
    line-height: 120%;
    font-weight: 600;
    transition: color .5s ease;
    text-transform: uppercase;

    &:hover {
        color: $color-active;
    }
}

.main-menu li:hover .menu-dropdown {
    display: block;
}
.current-language .global-icon {
    margin-right: 1.2rem;
    width: 3.2rem;
    height: 3.2rem;
} 
@media screen and (max-width: 1279px) {
    .nav-logo img {
        width: 14.1rem;
    }
}

@media screen and (max-width: 1023px) {
    .header {
        padding: 1rem 0
    }

    .menu-box {
        background-color: $color-active;
        padding: 3rem 0 0;
        position: fixed;
        left: 0;
        transform: translateX(-100%);
        top: 8.9rem;
        margin: 0;
        z-index: 100;
        width: 100%;
        bottom: 0;
        width: 100%;
        color: #fff;
        overflow: auto;
        flex-direction: column;
        align-items: flex-start;
        color: #fff;
        transition: transform .5s ease;
        will-change: right;
        bottom: 0;
        justify-content: space-between;
        background-image: url(../img/mob-header.svg);
        background-size: 100% auto;
        background-position: 50% 100%;
        background-repeat: no-repeat;

        &.active {
            transform: translateX(0);
        }


    }

    .nav-logo img {
        width: 10.1rem;
        vertical-align: top;
    }

    .header__info {
        display: flex;
    }

    .nav-inner {
        width: 100%;
        padding: 0 1.5rem
    }

    .search-link {
        margin-right: 2.7rem;
    }

    .main-menu {
        font-size: 1.6rem;
        display: block;
        text-align: left;

        &>li {
            margin-left: 0;
            margin-bottom: 2.1rem;
        }
    }

    .header.fixed .header-top {
        display: flex;
    }

    .nav-logo {
        padding-bottom: 0
    }

    .header-top {
        padding: .5rem 0 .5rem;
    }

    .header-bottom {
        padding: 0
    }

    .header-mob-bottom {
        border-radius: 1.6rem 1.6rem 0px 0px;
        border-top: 1px solid #C0E021;
        border-right: 1px solid #C0E021;
        border-left: 1px solid #C0E021;
        padding: 2rem 3rem;
        background: White;
        margin: 0 2rem 0;
        width: calc(100% - 4rem);

        .header-contacts-link {
            display: inline-flex;
            margin-right: 0;
            font-size: 1.6rem;
            font-weight: 600;
            padding: 0.8rem 1.5rem .8rem 0.5rem;
            i {
                margin-right: .8rem;
                width: 2.2rem;
                height: 2.2rem;
                font-size: 1rem;
            }
        }
    }

    .header-socials {
        margin-right: 0;
        display: flex;
        justify-content: space-around;
    }

    .header-contacts-link-wrap {
        margin-bottom: 1.5rem;
        text-align: center;
    }

    .mob-language {
        margin: 1.2rem -1rem 0;
        padding-top: 1.5rem;
        border-top: 1px solid rgba(31, 31, 31, 0.50);
        list-style: none;
        text-align: center;
        font-size: 1.4rem;

        a {
            color: rgba(31, 31, 31, 0.50);
            font-weight: 700;

            &.current {
                color: $text-color;
            }
        }

        li {
            display: inline-block;
            margin: 0 1rem;
        }
    }
    .main-menu > li.current-menu-item > a {
        color: $text-color;
    }
    .menu-dropdown {
        box-shadow: none;
        position: relative;
        left: 0;
        top: 0;
        margin: 0;
        padding: 1.5rem 2.3rem 0.5rem;
        ul  {
            width: 100%;
            li {
                    padding: 0.3rem 0 1.2rem;
            }
            a {
                color: #fff;
                font-size: 1.2rem;
        font-size: 1.9rem;
                &.current, &:hover {
                    color: $text-color;
                }
            }
        }
    }
    .menu-dropdown-list {
        background: transparent;
        text-align: center;
    }
    .main-menu i {
        display: inline-block;
        padding: .8rem .7rem .7rem .9rem;
        margin-left: 0
    }
    .main-menu  >li > a {
        display: flex;
        align-items: center;
        letter-spacing: 1.2px;
        font-size: 2.3rem
    }
    .main-menu  {
        li:hover .menu-dropdown {
        display: none;
    }
    li.opened .menu-dropdown {
        display: block;
    }
}
.socials__item img {
    vertical-align: top;
}
.header-socials {
    margin-right: 1rem
}
.global-links {
    margin-left: 0.7rem;
    margin-right: 2rem;
}
.header.fixed .nav-logo img {
        width: 10.1rem;
        }
        .header.fixed .nav-logo {
    padding-bottom: 0;
    padding-top: 0
}
}

@media screen and (max-width:767px) {
    .header-bottom {
        border: 0;
    }

    .header-contacts-link {
        display: none;
    }
    .menu-box {
        top: 6rem;
    }
    .bars-mob .cross {
    margin: -0.4rem 0 0 0;
}
    .menu-dropdown ul a {
        font-weight: 500;
        font-size: 1.6rem;
        text-transform: uppercase;
    }
    .main-menu  >li > a {
        font-size: 1.6rem
    }
    .header-socials {
        margin-right: 0;
    }
    .search-link {
        margin-right: 1.7rem;
    }
    .mob-global {
        color: $text-color;
        text-align: center;
        margin-top: 2.4rem;
            text-transform: uppercase;
            &.active {
                .icon-caret-menu {
                    transform: rotateX(-180deg);
                }
            }
            .icon-caret-menu 
            {
                display: inline-block;
                transition: transform .8s ease
            }
        .current-language {
            justify-content: center;
            font-weight: 700;
            padding:0;

        }
        .current-language .global-icon {
            width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.8rem;
    margin-left: 0;
        }
        .mob-language {
            display: none;
            margin-top: 1rem;
            padding-top: 0;
                border: 0;
                font-size: 1.4rem;
                font-weight: 500;
                a {
                    text-decoration: underline;

                }
                li {
                    display: block;
                }
                li + li {
                    margin-top: .8rem;
                }
        }
    }
}

@media screen and (min-width: 1024px) {
    .bars-mob {
        display: none;
    }
}