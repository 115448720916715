/* reset */
$min-width: 320px; 
$max-width:1920px; 
$base-font-size: 2rem; 
$base-line-height: 1.4; 
$base-font-family: 'Noto Sans', arial, sans-serif; 
$custom-font-family: 'Tenor Sans', arial, sans-serif; 

$default-transition:all 0.3s ease-in;

/* main variables */

//text, titles
$text-color: #1F1F1F;
$dark-bg: #1F1F1F;;
$title-color: $text-color;
$title-font-family: $base-font-family;
$color-active: #73AC43;
$line: #B5B5B5;

$color1: #C0E021;
$color2: #0D7B3E;
$dark-gray: #545454;
$link-color: $color-active;
$link-color-hover: #fff;
$base-bg: #fff;
$placeholder: rgba(#545454, .5);

$font-size-h1: 36px;
$line-height-h1: 42px;
$font-size-h2: 32px;
$line-height-h2: 37px;
$font-size-h3: 28px;
$line-height-h3: 32px;
