.section-head-inner {
    padding: 12rem 0 5rem;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    color: #fff;
    position: relative;
    min-height: 66rem;
    overflow: hidden;

    .wrapper {
        z-index: 1
    }

    &:before {
        position: absolute;
        content: '';
        display: block;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(90deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 100%) 0px -75.955px / 100% 171.259% no-repeat;
    }

    .navs-slider {
        margin-top: 0
    }

    .section-subtext {
        max-width: 64rem;

        p {
            padding: .7rem 0 .6rem;
        }
    }

    &_category {
        .h1 {
            font-size: 4.8rem;
            margin-bottom: 2rem
        }

        .breadcrumbs {
            margin-bottom: 8rem
        }

        .section-subtext {
            max-width: 100%;
        }

        .main-block__download {
            margin-top: 2.5rem;
        }

        .section-head__aside {
            text-align: right;
            padding-top: 14rem;
        }

        .section-head {
            align-items: flex-start;
        }

        .section-subtext ul li:before {
            transform-origin: 50% 50%;
            transition: color .6s ease, transform .6s ease
        }

        .section-subtext ul li:hover:before {
            color: $color1;
            transform: rotate(45deg);
        }
    }

    &_cagroservices-demo {
        padding: 11.5rem 0 5rem;

        .subtitle-main {
            margin-bottom: 1.1rem;
        }

        .section-head__aside {
            text-align: right;
            width: 32.3%;
        }

        .section-head__title {
            width: 67.7%;
        }

        .category-main-img {
            max-width: 90%;
        }

        .section-subtext {
            max-width: 100%;
        }
    }

    &_product {
        .section-head {
            align-items: center;
        }
    }

    &_about {
        min-height: 59.2rem;
    }

    &_results {
        min-height: 0;
        padding-bottom: 6.5rem;

        .breadcrumbs {
            margin-bottom: 7rem
        }

        .section-head__aside {
            padding-top: 14rem
        }
    }
}

.search-form-inner {
    display: flex;
    justify-content: flex-end;

    .input-search {
        height: 5.6rem;
        background: rgba(255, 255, 255, 0.8)
    }

    .search-form {
        max-width: 36.4rem;
        width: 100%;
    }

    .btn-search {
        left: 1.5rem;
        top: 1.1rem;
    }
}
.decor-127 {
    position: absolute;
    top: 93rem;
    left: 2%;
    width: 14rem;
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: contain;
    z-index: 0;

    &:before {
        content: '';
        display: block;
        padding-top: 100%
    }
}
.decor-128 {
width: 7.7rem;
    height: 5.6rem;
    position: absolute;
    z-index: 0;
    right: 6%;
    top: 49rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.decor-129 {
    position: absolute;
top: 125rem;
    right: 0px;
    width: 18.5%;
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: contain;
    z-index: 0;

    &:before {
        content: '';
        display: block;
        padding-top: 100%
    }
}
.section-head {
    display: flex;
    align-items: center;

    &__title {
        width: 65.7%;
        padding-right: 5rem
    }

    &__play {
        width: 40%;
        padding: 7rem 0 0 1%;
        color: $color-active;
        font-size: 17rem;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            transition: color .5s ease
        }

        i:hover {
            color: $color1;
        }
    }

    &__links {
        margin: 6.1rem -1rem 0;
        display: flex;
        flex-wrap: wrap;

        .btn-4,
        .btn {
            margin: 0 1rem;
            height: 5.6rem;
            line-height: 5.6rem;
            min-width: 23rem;
        }
    }

    &__aside {
        width: 34.3%;
        text-align: center;
    }
}

.category-main-img {
    max-width: 84%;
    border-radius: 50rem 50rem 50rem 0;

    &+.note-product {
        margin-top: 2.6rem;
        margin-left: auto;
    }
}

.note-product {
    color: $text-color;
    background: $color1;
    padding: 3.3rem 2.9rem 3.4rem;
    border-radius: .6rem;
    text-align: left;
    max-width: 46.5rem;

    &__note {
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 140%
    }

    &__btn {
        margin-top: 2.6rem
    }

    &__info {
        margin-top: 2rem;
        font-weight: 700;
        color: $color-active;
    }

    .h2-sm {
        margin-bottom: .3rem
    }
}

.section-subtext {
    ul {
        list-style: none;
        margin: 1.1rem 0;

        li {
            padding-left: 4.9rem;
            position: relative;


            &:before {
                @extend .icon-arrow-right;
                font-family: icomoon;
                font-size: 2.6rem;
                position: absolute;
                left: .5rem;
                top: -.5rem;
            }
        }

        li+li {
            margin-top: 2.4rem;
        }

        &:first-child {
            margin-top: 0
        }
    }

    strong {
        color: $color1;

    }
}

.decor-border {
    margin-top: 4rem;
    padding: 3rem 3.1rem 1.8rem;
    border: 1px solid $color-active;

}

.breadcrumbs {
    margin: 4.3rem 0 5rem;
}

.breadcrumb {
    color: $dark-gray;
    font-size: 1.8rem;
    line-height: 2.2rem;
    list-style: none;
}

.breadcrumb li {
    display: inline-block;
    vertical-align: middle;
}

.breadcrumb li:after {
    content: '/';
    margin: 0 .5rem;
}

.breadcrumb li:last-child:after {
    display: none;
}

.breadcrumb {
    a {
        color: $text-color;
    }

    a:hover {
        color: $color-active;
    }

    i {
        font-size: 120%
    }
}


.contacts-main,
.section-head-inner {
    .breadcrumb {
        color: #D1D1D1;
    }

    .breadcrumb a {
        color: #fff;
    }

    .breadcrumb a:hover {
        color: $color-active;
    }
}


.section-main-catalog {
    padding: 8rem 20.9% 7.5rem;
    position: relative;
}

.decor-13 {
    position: absolute;
    top: 0;
    right: .8rem;
    transform: translateY(-34%);
    width: 25%;
    max-width: 65.2rem;
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: contain;
    z-index: 0;

    &:before {
        content: '';
        display: block;
        padding-top: 100%
    }
}


.main-head {
    font-size: 5.2rem;

    span {
        color: $color-active
    }
}

.section-product {
    padding: 12.2rem 0 13rem;
    background: #F7F7F7;
}

.product-header {
    padding: 1rem 0 0
}

.product-subtext {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 120%;
}

.product-category {
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    color: $color1;
}

.product-head {
    margin-bottom: 1.1rem;
}

.product-head-sticky {
    background: #fff;
    padding: 4rem 4rem 4rem;
    border-radius: .8rem .8rem 0px 0px;

    .section-head__links {
        margin-top: 2rem;
        justify-content: center;

        a {
            width: 100%
        }
    }

    .product-head {
        color: $color-active;
    }

    .product-category {
        color: $text-color;
    }
}

.product {
    border-radius: .8rem;
    background: #fff;

    &__description {
        padding-top: 8.1rem;
        padding-bottom: 5.3rem
    }

    &__text {
        p {
            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.section-categories {
    padding: 4rem 0 7rem;

    &_search {
        padding: 6rem 0 0;
        position: relative;

    }
}

.categories-list {
    margin: 0 -1.2rem;
    display: flex;
    flex-wrap: wrap;

    &__item {
        width: calc(25% - 2.4rem);
        margin: 1.2rem;
        position: relative;
        display: flex;
        justify-content: flex-end;
        border-radius: 0 0 .4rem .4rem;
        overflow: hidden;
        flex-direction: column;
        border-radius: .4rem;
        overflow: hidden;

        &:before {
            content: '';
            display: block;
            padding-top: 110%
        }

        &:hover {
            .categories-list__bottom {
                background: $color1;
                color: $text-color;
            }

            .categories-list__link {
                transform: rotate(-45deg);
            }

            .categories-list__bottom {
                transform: translateY(-3rem);
            }
        }
    }

    &__img {
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: .4rem;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            vertical-align: top;
            object-fit: cover;
            object-position: 50% 50%
        }
    }

    &__title {
        font-size: 3.2rem;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
    }

    &__info {
        margin-top: .8rem;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
    }

    &__bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        max-height: 100%;
        overflow: auto;
        border-radius: 0 0 .4rem .4rem;
        background: var(--Transparent-black, rgba(31, 31, 31, 0.50));
        backdrop-filter: blur(37.5px);
        color: #fff;
        padding: 3.2rem 3.6rem 3.2rem;
        display: flex;
        z-index: 1;
        align-items: center;
        justify-content: space-between;
        transition: background .8s ease, color .8s ease, transform .8s ease;
    }

    &__link {
        font-size: 2.5rem;
        transition: transform .8s ease;
        transform-origin: 0 0;
    }

    &__badge {
        padding: 1rem 1.5rem 1rem;
        color: $text-color;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
        position: absolute;
        right: 3rem;
        top: 0;

        div {
            display: block;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            transform: translate(0, -50%) rotate(45deg);
            background: $color1;
            z-index: 0;

            &:before {
                content: '';
                display: block;
                padding-top: 100%;

            }
        }

        span {
            position: relative;
            z-index: 1
        }
    }
}

.product-info-sticky {
    padding: 3.2rem 3.6rem 3.6rem;
    border-radius: 0 0 .8rem .8rem;
    background: linear-gradient(0deg, rgba(115, 172, 67, 0.20) 0%, rgba(115, 172, 67, 0.20) 100%), #F7F7F7;
}

.product-info {
    &+& {
        margin-top: 2.3rem
    }

    &__value {
        font-size: 2rem;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
    }

    &__label {
        margin-bottom: .2rem;
    }
}

.article-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
}

.decor-16 {
    position: absolute;
    top: 7.6%;
    right: -5rem;
    width: 47.6rem;
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: contain;
    z-index: 0;

    &:before {
        content: '';
        display: block;
        padding-top: 100%
    }
}

.article-wrap {
    width: calc(100% - 51.6rem);
    padding-right: 2.2rem;
    position: relative;
}

.agroservices-demo+.agroservices-demo {
    margin-top: 8.1rem
}

.product {
    position: relative;
    z-index: 1;

    .list-3 {
        margin: 2rem 0 1rem;
    }

    .decor-22 {
        bottom: -12rem;
        right: -10.6rem;
        width: 30.5rem;
    }
}

.article-aside {
    width: 51.6rem;
}

.article-aside__sticky {
    position: sticky;
    top: 8.3rem;
}

.block-article {
    padding-left: 6rem;
    padding-right: 6rem;
}

.filters-wrap {
    display: flex;
    align-items: center;
    margin: 0 -1.9rem 4rem 0;

    form {
        display: block;
        flex: 1 1 100%
    }
}

.filter-form {
    .box-field {
        margin: 0 1.9rem 0 0;
        display: inline-block;
    }
}

.filters-icon {
    font-size: 2.4rem;
    flex-shrink: 0;
    margin-right: 2.2rem;
    color: $dark-gray;
}

.btn-reset {
    color: $dark-gray;
    padding: .2rem 0;
    background: transparent;
    border: 0;
    font-size: 2rem;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    display: none;

    &.visible {
        display: block;
    }
}

.section-blog {
    padding: 6rem 1% 8.5rem;
    position: relative;

    .wrapper {
        z-index: 1;
    }

    .decor-23 {
        top: 36rem;
        width: 63.7rem;
    }

    .decor-24 {
        position: absolute;
        bottom: 1rem;
        right: 0;
    }
}

.blog {
    margin: 6rem -1.4rem 0;
    display: flex;
    flex-wrap: wrap;

    .news__item {
        margin: 0 1.4rem 3.3rem;
        width: calc(25% - 2.8rem)
    }

    &+.box-paging-load {
        margin-top: 2.4rem
    }
}

.filter-categories-2 {
    margin-bottom: 2.5rem;
    display: flex;
    overflow: auto;
    white-space: nowrap;
}

.filter-cat-2 {
    background: #fff;
    padding: 1.2rem 2.4rem;
    border-radius: .8rem;
    margin-right: .8rem;
    box-shadow: 0px .2rem .4rem -.2rem rgba(24, 39, 75, 0.12), 0px .4rem .4rem -.2rem rgba(24, 39, 75, 0.08);
    color: $text-color;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    flex: 1 1 100%;
    text-align: center;

    &:last-child {
        margin-right: 0
    }

    &.selected,
    &.active,
    &:hover {
        background: $text-color;
        color: #fff;
        box-shadow: none;
    }
}

.section-head-inner_blog {
    padding-bottom: 6rem;

    &:before {
        z-index: 1;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 27.79%, rgba(0, 0, 0, 0.46) 42.23%, #000 100%);
    }

    .wrapper {
        z-index: 2
    }

    .section-subtext {
        max-width: 100%
    }

    .section-head {
        align-items: flex-start;
        min-height: 55.2rem;
    }

    .section-head {
        min-height: 40.2rem;
    }
}

.blog-slider {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    &.slick-slider {
        position: absolute;
    }

    .slick-list,
    .slick-track {
        height: 100%
    }

    .slick-slide {
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;

        div {
            display: flex;
            flex-direction: column;
            flex: 1 1 100%;
        }
    }

    &__item {

        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
    }

    &.slick-initialized .slick-slide {
        display: flex;
    }
}

.main-video-icon {
    color: $color1;

    &:hover {
        color: $color-active;
    }
}

.article-section {
    background: #F7F7F7;
    overflow: hidden;
}

.article {
    background: #fff;
    padding: 7.7rem 7.5% 6rem;
    border-right: 1px solid $color1;
    border-left: 1px solid $color1;

    max-width: 1300px;
    margin: 0 auto;
    z-index: 1;
    position: relative;
    color: #000;

    h2 {
        margin-top: 5rem
    }

    ul {
        list-style: none;
        margin: .2rem 0 .8rem 4rem;

        li {
            padding-left: 2.5rem;
            position: relative;

            &+& {
                margin-top: 1.5rem;
            }

            &:before {
                content: '';
                width: 1rem;
                height: 1rem;
                transform: rotate(-45deg);
                background: $color2;
                position: absolute;
                left: 0;
                top: .7rem;
            }
        }
    }
}

figure {
    margin: 3rem 0;

    img {
        vertical-align: top;
        width: 100%
    }
}

figcaption {
    display: flex;
    align-items: flex-start;
    border-radius: 0px 0px .4rem .4rem;
    background: #F7F7F7;
    padding: 1.6rem 4rem;
    font-size: 90%;

}

.article-head {
    margin-bottom: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .news__categories {
        margin: 0 1rem 0 0;
    }
}

.figure-count {
    flex-shrink: 0;
    margin-right: 2rem;
}

.article-date {
    font-size: 1.8rem;
    margin-bottom: .8rem
}

figure {
    border-radius: .4rem;
    overflow: hidden;
    display: block;
}

.figure-groups {
    margin: 0 -1.2rem;
    display: flex;

    figure {
        flex: 1 1 100%;
        margin: 2.5rem 1.2rem;
    }
}

.section-inner-top {
    padding-top: 10rem;

    .breadcrumb a {
        color: $text-color;

        &:hover {
            color: $color-active;
        }
    }
}

.section-agroservices {
    padding-bottom: 9.9rem;
    background: #F7F7F7;

    .breadcrumbs {
        margin: 6.3rem 0 5.5rem;
    }
}

.financial-decisions {
    margin: 0 -1.2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &__item {
        border-radius: 1.6rem;
        margin: 0 1.2rem 2.4rem;
        display: block;
        width: calc(33.33% - 2.4rem);
        overflow: hidden;
        background: #fff;
        color: $text-color;

        &:hover,
        &.active {
            background: $color1;
            box-shadow: 0 .3rem 1rem rgba(0, 0, 0, .5);

            .financial-decisions__date {
                color: $text-color
            }
        }
    }

    &__img {
        position: relative;
        flex-shrink: 0;

        &:before {
            content: '';
            display: block;
            padding-top: 64%
        }

        img {
            vertical-align: top;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &__title {
        font-size: 3.2rem;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__head {
        min-height: 11.6rem;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
        justify-content: space-between;
    }

    &__date {
        margin-top: 1.5rem;
        color: #D1D1D1;
        font-size: 1.2rem;
        font-weight: 500;
        transition: color 0.3s ease-in;
    }
}

.filter-categories-2_1 {
    display: flex;
    padding: .3rem 0 .5rem;
    margin-bottom: 5.6rem;

    .filter-cat-2 {
        text-align: center;
        flex: 1 1 100%;
        box-shadow: 0px .2rem .4rem -.2rem rgba(24, 39, 75, 0.12), 0px .4rem .4rem -.2rem rgba(24, 39, 75, 0.08);

    }

    &_1 {
        margin-bottom: 3.6rem;
    }
}

.section-history {
    padding: 8rem 0 2rem;

    .h2 {
        margin-bottom: 4.1rem
    }
}
.history {
    .navs-slider {
        justify-content: flex-end;
    }
}
.history-dots {
    margin-bottom: 6rem;
    display: flex;

    &__item {
        flex: 1 1 100%;
        padding: .4rem 0;
        font-weight: 700;
        border: 1px solid $color1;
        text-align: center;
        cursor: pointer;
        transition: color .8s ease, background .8s ease;

        &:hover,
        &.current {
            background: $color1;
            color: #fff;
        }
    }
}

.history-sliders {
    display: flex;

    &__images {
        width: 51%;
        padding-right: 2.6rem;
        .slick-track {
            display: block;
        }
        img {
            vertical-align: top
        }
    }

    &__texts {
        width: 49%;
        padding-left: 3.3rem;

        .slick-track {
            display: block;
        }

    }

    &__title {
        font-size: 3.2rem;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
    }

    &__text {
        padding: 2.2rem 2.4rem;
    }
}

.list-arrow {
    ul {
        list-style: none;
        margin: 1.4rem 0 1rem;

        li {
            padding-left: 5rem;
            position: relative;


            &:before {
                @extend .icon-arrow-right;
                font-family: icomoon;
                font-size: 2.2rem;
                position: absolute;
                left: 0;
                top: .3rem;
            }
        }

        li+li {
            margin-top: 3.2rem;
        }
    }

    strong {
        color: $color1;

    }
}

.section-agroservices-demo {
    padding: 11.9rem 0 9rem
}

.map-1 {
    width: 100%;
    height: 74.7rem;
    margin: 3rem 0;
}

.links-agro {
    border-radius: .8rem;
    border: 1px solid $text-color;
    background: #FFF;
    color: $text-color;
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;

    &+& {
        margin-top: 3rem;
    }

    &:hover {
        background: $color1;
        color: $text-color;
        border-color: $color1
    }

    &.active {
        pointer-events: none;
        background: $text-color;
        color: $color1;
    }

    i {
        margin-left: 3rem
    }
}

.section-contacts-trade {
    .breadcrumbs {
        margin: 6.7rem 0 5.7rem;
    }
}

.contacts-main {
    margin-top: 15rem;
    padding-top: 1px;
    position: relative;
    z-index: 1;

    .wrapper {
        z-index: 1
    }

    .section-head__title {
        width: 100%;
        padding-right: 0;
    }

    .breadcrumbs {
        margin-top: 1.6rem
    }
}

.contacts-list-wrap {
    overflow: hidden;
    position: relative;
    z-index: 1;
    padding-bottom: 12rem;
    pointer-events: none;

    .wrapper {
        pointer-events: none;
    }
}

.map-wrapper {
    position: relative;
}

.contacts-list {
    column-gap: 5.5rem;
    columns: 2;
    margin-top: 4.1rem;

    &-wrapper {
        position: relative;
        width: 43.5%;
        border-radius: 0px .8rem .8rem 0px;
        padding: 6rem 6% 2.5rem 0;
        background: #fff;
        pointer-events: auto;

        .h1,
        .h1-sm {
            position: relative;
            z-index: 1
        }

        &:before {
            content: '';
            display: block;
            border-radius: 0px .8rem .8rem 0px;
            background: #fff;
            left: -10000px;
            top: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            z-index: 0;

        }
    }

    .h1 {
        position: relative;
        z-index: 1;
    }


    &__item {
        position: relative;
        z-index: 1;
        display: inline-flex;
        width: 100%;
        break-inside: avoid;
        margin-bottom: 2.8rem;
        align-items: flex-start;

        &:last-child {
            .contacts-list__icon {
                margin-top: .2rem;
            }
        }
    }

    &__icon {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        background: $color2;
        flex-shrink: 0;
        margin-top: -.9rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        transition: background .7s ease, color .7s ease;
        font-size: 90%;

        &.icon-mail {
            font-size: 1.5rem
        }
    }

    &__details {
        padding-left: 2.4rem;
    }

    &__label {
        font-size: 1.5rem;
        color: $dark-gray;
        font-weight: 700;
        line-height: 115%;
        margin-bottom: 1.3rem;
    }

    &__text {
        line-height: 1.2
    }

    a {
        color: inherit;
    }
}

.map-2 {
    background: $text-color;
    position: absolute;
    z-index: 1;

    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}

.section-agroservices_demo {
    padding-bottom: 0;

    .breadcrumbs {
        margin-top: 6.3rem
    }
}

.contacts-trade-wrapper {
    background: #F7F7F7;
}

.contacts-trade-form-wrap {
    background: #F7F7F7;
    padding: 0 2.5% 3.1rem;


    .h1 {
        font-size: 4.8rem;
        margin-bottom: 3.7rem
    }

    &_distributors {
        margin-top: -1.5rem;
        padding-bottom: 2.1rem;

        .h1 {
            margin-bottom: 0
        }
    }
}

.contacts-tsearch-wrap {
    background: $color-active;
    padding: 3.2rem 0 3rem;

    .search-form {
        max-width: 55.3rem;
        margin: 0 auto;
        background: transparent
    }

    .input-search {
        height: 5.6rem
    }

    .btn-search {
        left: 1.6rem;
        top: 1.1rem;
    }
}

.distributors-tabs {
    padding: 0 0 12.9rem;

    .tab-content {
        padding-top: 3.7rem;
    }
}

.contacts-representatives-wrap {
    padding: 2.6rem 2.5% 0;
    background: #F7F7F7;

}

.trade-form {
    margin-right: -1.7rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__item {
        margin-right: 1.7rem;

        &_1 {
            width: 44rem
        }
        &_4 {
            margin-top: 3.8rem;
            width: 100%;
            display: none;
        }
    }

    .ms-choice {
        text-transform: none;
        background: $color-active;
        color: #fff;
        font-size: 2rem;
        padding: 1.1rem 5.5rem 1.1rem 1.8rem;
        height: 5.6rem
    }

    .ms-choice>span {
        font-weight: 400;
        position: static;
        opacity: 1;
    }

    .ms-choice>span.placeholder {
        color: rgba(#fff, .7)
    }

    .ms-choice>div.icon-caret {
        background-image: url(../img/icons-svg/caret.svg);
        height: 3.2rem;
        width: 3.2rem;
        margin-top: -1.6rem;
        background-size: contain;
        background-position: 50% 50%;

        &:before {
            display: none;
        }
    }
}

.btn_contact-form {
    border-radius: .4rem
}

.section-representatives {
    position: relative;
    padding: 4rem 0 7rem;
}

.representatives-wrapper {
    position: relative;
    z-index: 1
}

.representatives {
    margin: 4rem 0 0;

    &__item {
        margin: 0 0 2rem;
        min-height: 33.9rem;
        display: flex;
        border-radius: 1.6rem;
        background: #fff;
        overflow: hidden;
        transition: box-shadow 1s ease;

        &:hover {
            box-shadow: 0 .3rem 1rem rgba(0, 0, 0, .15);

            .contacts-list__icon {
                background: $color1;
                color: $text-color;
            }
        }

    }

    &__head {
        display: flex;
        align-items: center;
    }

    &__icon {
        width: 8rem;
        height: 8rem;
        margin-right: 1.2rem;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            vertical-align: top;
            max-width: 90%;
            max-height: 90%
        }
    }

    &__left {
        width: 29%;
        padding: 3.6rem 4rem 3.3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 1.6rem 0 0 1.6rem;
        border: 1px solid $color1;
        border-right-width: 0
    }

    &__right {
        padding: 3.3rem 4rem 3.3rem;
        width: 71%;
        background: $color-active;
        color: #fff;
        display: flex;
        justify-content: space-between;

        ul {
            list-style: none;
        }

        li+li {
            margin-top: .2rem
        }
    }

    &__title {
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
    }

    &__email {
        font-weight: 700;
        margin-top: 2rem;

        a {
            color: $color2;
        }
    }

    &__phone {
        margin: 2rem 0 0;
        width: 100%;
        align-items: center;

        a {
            color: inherit;
        }
    }

    .slick-arrow {
        color: #fff;
        border-color: #fff;

        &:hover {
            border-color: $text-color;
            color: $text-color;
        }
    }

    .navs-slider_vertical {
        justify-content: flex-start;
        margin-top: 1.8rem;
        width: 5.6rem
    }
}

.representatives-list {
    width: calc(100% - 5.6rem);

    .slick-track {
        margin-left: 0
    }

    &__item {
        padding-right: 20px
    }

    &__title {
        font-weight: 700;
        margin-bottom: 20px;
    }

}

.distributor {

    margin: 4rem -1.2rem 0;
    display: flex;
    flex-wrap: wrap;

    &__item {
        padding: 4rem 3.7% 4rem;
        width: calc(33.333% - 2.4rem);
        margin: 0 1.2rem 2.4rem;
        border-radius: 1.6rem;
        border: 1px solid $color-active;
        background: #FFF;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 1.6rem;
        border: 1px solid #73AC43;
        background: #fff;
        min-height: 43.8rem;
        transition: box-shadow 1s ease;

        &:hover {
            box-shadow: 0 .3rem 1rem rgba(0, 0, 0, .15);

            .contacts-list__icon {
                background: $color1;
                color: $text-color;
            }
        }
    }

    &__title {
        margin-bottom: .2rem;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
    }

    &__contatcts-item {
        margin: 3rem 0 0;

        a {
            color: inherit;
        }
    }

    &__link {
        font-weight: 700;
        margin-top: 2rem;

        a {
            color: $color-active;
        }
    }

    .contacts-list__details {
        min-height: 3.2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .contacts-list__icon {
        margin-top: 0
    }

    .contacts-list__text {
        line-height: 1.1;
    }

    &+.box-paging-load {
        margin-top: 2.3rem;

        &+.box-paging {
            margin-top: 1.8rem;
            padding-top: 1.8rem;
        }
    }
}

.filter-categories-2_2 {
    margin-top: 2.2rem;

    .filter-cat-2 {
        flex: 0 1 auto;
        min-width: 22.3rem;
        margin-right: 1.9rem;

        &:last-child {
            margin-right: 0;
        }
    }
}

.section-politica-head {
    padding: 4rem 0 7rem;
    background: #F7F7F7
}

.section-politica {
    .article {
        border-radius: 1.6rem;
        border: 1px solid $color1;
    }

    .article-head {
        display: block;
        text-align: right;
    }

    .article-date {
        font-size: 1.2rem;
        font-weight: 500
    }

    ol {
        margin: 1rem 0 1rem 1.7rem;

        ul {
            margin: .5rem 0 .5rem 1.7rem;
        }
    }

    ul {
        list-style: disc;

        li {
            padding: 0;

            &:before {
                display: none;
            }
        }
    }
}

.section-error-page {
    padding: 10.2rem 0 7rem;
    background: #F7F7F7;
    overflow: hidden;

    .decor-22 {
        width: 30.8rem;
        bottom: -5rem;
        right: 3.8rem;
    }

    .decor-17 {
        top: -17rem;
        left: -6.2rem;
        width: 48rem;
    }
}

.error-page {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 1.6rem;
    max-width: 132rem;
    margin: 0 auto;
    padding: 9.5rem 10% 11rem;
    box-shadow: .2rem 0 1.5rem rgba(#18274B, .12);
    border: 1px solid $color1;
    border-bottom-width: 0;
    background: #fff;
    position: relative;
    z-index: 1;

    &__decor {
        font-size: 24.8rem;
        width: 55%;
        color: $color-active;
        line-height: 1;
        font-weight: 700;
        text-shadow: .2rem 0 .5rem rgba(#18274B, .12)
    }

    &__heading {
        width: 45%;
        padding-left: 4rem;
    }

    &__title {
        color: $color-active;
        margin-bottom: 1.7rem
    }

    &__link {
        margin-top: 3.6rem;

        .btn {
            height: 5rem;
            line-height: 5rem;
        }
    }

    &__text {
        font-size: 3.2rem;
        font-weight: 700;
        text-transform: uppercase;
    }
}

.filter-categories-2_3 {
    max-width: 100%
}

.ios .representatives .slick-arrow:hover {
    border-color: #fff;
    color: #fff;
}
.search-list {
    padding: 0 5%;
    max-width: 88%;
    &__item + &__item {
        margin-top: 5rem;
    }
    &__title {
        font-size: 3.2rem;
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;

        a {
            color: $text-color;
            &:hover {
                color: $color-active;
            }
        }
    }
    &__category {
        margin-top: 2rem;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;

        a {
            color: $text-color;
            &:hover {
                color: $color-active;
            }
        }
    }
    &__text {
        margin-top: 1.7rem;
        line-height: 123%;
        p {
            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
@media screen and (max-width: 1760px) {
    .article-wrap {
        width: calc(100% - 41.6rem);
        padding-right: 4rem;
    }

    .article-aside {
        width: 41.6rem;
    }

    .contacts-list-wrapper {
        padding-right: 4rem
    }
}

@media screen and (max-width: 1440px) {
    .block-article {
        padding-left: 5rem;
        padding-right: 5rem;
    }
}

@media screen and (max-width: 1366px) {
    .blog {
        .news__item {
            width: calc(33.333% - 2.8rem)
        }
    }

    .contacts-list-wrapper {
        width: 48%
    }

    .categories-list__bottom {
        padding: 2.8rem 3rem
    }

    .categories-list__title {
        font-size: 2.8rem;
    }

    .section-more-products {
        padding: 5rem 0 5rem;
    }

    .section-head__play {
        font-size: 13rem
    }

    .article-wrap {
        width: calc(100% - 38.6rem);
    }

    .article-aside {
        width: 38.6rem;
    }

    .block-article {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .distributor__item {
        padding: 3rem 3%;
        min-height: 37.8rem;
    }

    .links-agro {
        font-size: 3rem
    }
    .search-list__title {
        font-size: 3rem;
    }
}

@media screen and (max-width: 1280px) {
    .links-agro {
        font-size: 2.8rem;
    }
    .decor-127 {
        top: 73rem;
        left: 0;
        width: 10rem;
    }
    .decor-129 {
        display: none;
    }
    .decor-128 {
        right: 1.5%
    }
    .search-list {
        padding: 0 4%;
        max-width: 98%;
    }
}
@media screen and (max-width: 1279px) {
    .categories-list__title {
        font-size: 2.4rem;
    }
}
@media screen and (max-width: 1024px) {
    .categories-list__item {
        width: calc(33.333% - 2.4rem);
    }
}
@media screen and (max-width: 1023px) {
    .section-head {
        flex-wrap: wrap;

        &__title {
            width: 100%;
            padding-right: 0
        }
    }

    .section-head__aside {
        width: auto;
        max-width: 45rem
    }
    .section-head-inner_category .section-head__aside {
        padding-top: 4rem;
    }


    .article-wrap {
        width: 100%;
        padding-right: 0;
    }

    .article-aside {
        display: none;
    }

    .decor-17,
    .decor-18,
    .decor-19,
    .decor-20,
    .decor-21,
    .decor-22 {
        display: none;
    }

    .categories-list__title {
        font-size: 1.6rem;
    }

    .categories-list__bottom {
        padding: .8rem 2rem .8rem;
    }

    .categories-list {
        margin: 0 -.8rem;
    }

    .categories-list__item {
        width: calc(50% - 1.6rem);
        margin: .6rem;
        border-radius: 0
    }

    .box-paging-load {
        margin-top: 3.4rem;
    }

    .categories-list__badge {
        padding: .6rem 1.5rem .8rem;
        font-size: 1rem;
    }

    .blog {
        .news__item {
            width: calc(50% - 2.8rem)
        }
    }

    .links-agro.active {
        pointer-events: auto;
    }

    .contacts-list {
        columns: 1
    }

    .representatives {
        margin: 0;

        &__item {
            width: 100%;
            margin: 0 0 2rem;
            min-height: 0
        }
    }

    .trade-form .ms-choice {
        font-size: 1.6rem
    }

    .trade-form__item_1 {
        width: 40rem;
    }

    .distributor__item {
        padding: 3rem 3rem 3rem;
        width: calc(50% - 2.4rem);
    }

    .error-page__decor {
        font-size: 20.8rem;
    }

    .section-head-inner_product {
        .section-head__title {
            width: 65.7%;
        }

        .section-head__play {
            width: 34.3%;
        }
    }

    .section-product {
        padding: 8.2rem 0 5rem;
    }

    .product__description {
        padding-top: 6.1rem;
        padding-bottom: 4.3rem;
    }

    .section-contacts-trade .breadcrumbs {
        margin: 3.7rem 0 2.7rem;
    }

    .representatives__left {
        width: 33%;
    }

    .representatives__right {
        width: 67%
    }
    .decor-127, .decor-128 {
        display: none;
    }
    .search-list {
        padding: 0;
        max-width: 100%;
    }
    .section-head-inner_results .section-head__aside {
        padding-top: 5rem;
    }
    
    .links-agro {
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 767px) {
    .section-head-inner {
        margin-top: 7rem;
        padding: 1.4rem 0 4rem;
        min-height: 0;

        .main-block__download {
            display: block;
            margin: 2.8rem 0 0 0;
        }

        .section-subtext {
            line-height: 135%;
        }
    }

    .section-inner-top {
        padding-top: 6.2rem;
    }

    .section-head-inner_category {
        min-height: 25.4rem;

        .section-subtext,
        .h1 {
            display: none;
        }
    }

    .section-head-inner_blog {
        .h1 {
            font-size: 2.4rem;
            margin-bottom: 2.9rem;
        }

        .breadcrumbs {
            margin: 0 0 2.9rem;
        }

        .section-head {
            min-height: 0;
        }

        .slick-dots {
            margin: 3.6rem 0 0;
        }
    }

    .article-section {
        background: transparent;

        .article {
            margin: 0 -2rem;
        }
    }

    .article {
        border-radius: 0;
        border: 0;
    }

    .note-product {
        display: none;
    }

    .section-main-catalog {
        padding: 3.3rem 0 3.5rem;
    }

    .section-head__aside {
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        flex-direction: row;

        img {
            width: 10rem;
            margin-right: 1.2rem;
        }

        .h1 {
            display: block;
            margin: 0;
            width: calc(100% - 11.2rem);
            text-align: left
        }
    }

    .breadcrumbs {
        margin: 0 0 2.5rem;
    }

    .breadcrumb i {
        font-size: 2.1rem;
        margin-right: .4rem
    }

    .breadcrumb {
        font-size: 1.2rem
    }

    .main-catalog .main-categories__item {
        width: calc(50% - 1rem)
    }

    .block-article {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .categories-list__bottom {
        padding: .9rem 1rem;
    }

    .categories-list__title {
        font-size: 1.2rem;
    }

    .categories-list__info {
        font-size: .9rem;
    }

    .section-categories {
        padding: 1.8rem 0 0;
    }

    .categories-list__badge {
        padding: .4rem .6rem .2rem;
        font-size: .9rem;
        right: .7rem;
    }

    .categories-list__link {
        font-size: 1.3rem;
    }

    .categories-list__info {
        margin-top: .5rem;
    }


    .section-blog {
        padding: 4rem 0 4.5rem;

    }

    .blog {
        margin-left: 0;
        margin-right: 0;

        .news__item {
            margin: 0 0 1.4rem;
            width: 100%
        }
    }

    .section-head-inner_blog {
        padding-bottom: 4rem;
    }

    .section-head-inner_product {
        min-height: 36rem;

        .btn-4+.btn-download {
            margin-top: 1rem
        }


        .breadcrumbs {
            margin: 2.7rem 0 2rem;
        }
    }

    .product-category {
        margin-bottom: .7rem;
        font-size: 1.4rem;
    }

    .product-head {
        font-size: 4rem;
        margin-bottom: .5rem;
    }

    .product-subtext {
        font-size: 1.8rem;
    }

    .main-video-icon {
        margin-right: 10%;
        font-size: 8rem;
        flex-shrink: 0
    }

    .section-head__links .btn-4,
    .section-head__links .btn {
        margin: 0;
        height: 3.8rem;
        line-height: 3.8rem;
        min-width: 12rem;
        padding: 0 1.7rem;
    }

    .section-head__links {
        margin: 2.5rem 0 0 0;
        display: flex;
        flex-direction: column;
        div + div {
            margin-top: 1rem
        } 
    }

    .product-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__titles {
            padding-right: 1.5rem
        }
    }

    .section-product {
        padding: 4rem 0 .2rem;
        background: transparent;

        .wrapper {
            padding-left: 0;
            padding-right: 0
        }
    }

    .product .list-3 {
        margin: .6rem 0 .5rem;
    }

    .block-article {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .product__description {
        padding-top: 0;
        padding-bottom: 3.7rem;
    }

    .article-head {
        display: block;

        .news__categories {
            margin: 0;
        }
    }

    .article-date {
        margin-top: -.4rem;
        font-size: 1.2rem
    }

    .article {
        padding: 4rem 2rem 4rem;

        h2 {
            font-size: 1.6rem;
            margin-top: 2.3rem;
        }

        ul {
            list-style: none;
            margin: 0px 0 .6rem .3rem;
        }
    }

    .figure-groups {
        display: block;
    }

    figcaption {
        padding: 1.5rem 3.9rem;
    }

    .figure-count {
        margin-right: 1.1rem;
    }

    .figure-groups figure {
        margin: 1rem 1.2rem;
    }

    .history {
        display: flex;
        flex-wrap: wrap;

        .h2 {
            margin-bottom: 1.9rem;
            text-align: center;
        }

        .navs-slider {
            width: 100%;
            margin-top: 0;
        }

        .slick-arrow {
            font-size: 1.1rem;
            height: 4rem;
            width: 4rem;
        }
    }

    .history-dots {
        flex-shrink: 0;
        margin: 0 1.9rem 0 0;
        display: block;
        width: 5.7rem;
        margin-left: -2rem;
    }

    .history-dots__item {
        padding: .6rem 0;
    }

    .history-content {
        width: calc(100% - 5.7rem)
    }

    .history-sliders__title {
        font-size: 1.8rem;
    }

    .history-sliders {
        display: block;
        width: 100%;

        &__images {
            width: 100%;
            padding-right: 0;
            margin: 0 0 2rem;
        }

        &__texts {
            width: 100%;
            padding-left: 0;
            text-align: center;
        }

        &__text {
            padding: 2.2rem 0;
        }
    }

    .financial-decisions__item {
        margin: 0;
        width: 100%
    }

    .financial-decisions__head {
        min-height: 0;
        padding: 1.6rem 2rem;
    }

    .financial-decisions__title {
        font-size: 1.7rem;

        line-height: 137%;
    }

    .financial-decisions__date {
        margin-top: .5rem;
    }

    .section-agroservices {
        padding-bottom: 2rem;
        background: #fff;

        &_demo {
            padding-bottom: 0
        }
    }

    .agroservices-tabs_main {
        .tab {
            height: auto
        }
    }

    .map-1 {
        height: 23.4rem;
        margin: 3rem 0 3rem;
    }

    .links-agro {
        margin: 0 2rem;
        padding: 1.2rem 2rem;
        font-size: 1.4rem
    }

    .representatives__title {
        font-size: 1.6rem
    }

    .representatives__icon {
        width: 6.5rem;
        height: 6.5rem;
    }

    .representatives__phone {
        margin-top: 2.4rem
    }

    .representatives__email {
        margin-top: .8rem;
    }

    .trade-form .ms-choice {
        font-size: 1.4rem
    }

    .trade-form {
        margin-right: 0;
        display: block;

        .ms-drop ul>li {
            padding: .5rem 1.5rem;
        }
    }

    .trade-form__item_1 {
        width: 100%;
        margin-right: 0;

        .autoComplete_wrapper>input {
            width: 100%
        }
    }

    .trade-form__item+.trade-form__item {
        margin-top: 1.2rem
    }

    .btn_contact-form {
        font-size: 1.4rem
    }

    .representatives__left {
        padding: 2rem 2rem 2rem;
    }

    .representatives__right {
        padding: 1.5rem 2rem 1.7rem;
    }

    .contacts-main {
        margin-top: 7.4rem;
    }

    .filter-categories-2 {
        margin-left: -2rem;
        margin-right: -2rem;
        padding: 0 2rem .5rem;
        margin-bottom: 1.4rem;
        max-width: none;

        &::-webkit-scrollbar {
            height: .4rem;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $dark-bg;
            outline: $dark-bg;
        }
    }

    .blog {
        margin-top: 0
    }

    .section-error-page {
        padding: 3.2rem 0 5rem;

        .decor-17,
        .decor-22 {
            display: block;
        }

        .decor-17 {
            top: -3rem;
            left: -10.2rem;
            width: 19rem;
        }

        .decor-22 {
            width: 9.8rem;
            bottom: -2rem;
            right: 0.8rem;
        }
    }

    .error-page {
        display: block;
        padding: 0 2.5rem 2rem;
        border-radius: .4rem;

        &__decor {
            text-align: center;
            font-size: 13.4rem;
            width: 100%;
        }

        &__heading {
            width: 100%;
            padding: 2.1rem 0 0 16px
        }

        &__text {
            font-size: 1.6rem;
        }

        &__title {
            margin-bottom: 1rem;
        }

        &__link {
            margin-top: 2.6rem;
        }

        .btn {
            font-size: 1.4rem;
            height: 3.8rem;
            line-height: 3.8rem;
        }
    }

    .section-contacts-trade .breadcrumbs {
        margin: 2.7rem 0 2.7rem;
    }

    .filter-categories-2_1,
    .filter-categories-2_1-2 {
        margin-bottom: 1.1rem;

        .filter-cat-2 {
            margin-bottom: .4rem
        }
    }

    .contacts-trade-form-wrap .h1 {
        font-size: 2rem;
        margin-bottom: 1.3rem;
    }
    .trade-form .ms-choice {
        padding-top: .8rem
    }    
    .trade-form .ms-choice {
        height: 3.6rem
    }

    .trade-form .ms-choice>div.icon-caret {
        height: 2rem;
        width: 2rem;
        margin-top: -1rem;
    }

    .representatives__item {
        margin: 0 0 1.1rem;
    }

    .contacts-list__icon {
        width: 2.5rem;
        height: 2.5rem;
        margin-top: -.4rem;
    }

    .section-representatives {
        padding: 3.9rem 0 0;
    }

    .contacts-list-wrapper {
        width: 86%;
        margin-top: 4rem;
        padding: 2.2rem 2rem 0 0;
    }

    .map-2 {
        top: 19.5rem;
    }

    .contacts-main {
        .breadcrumb {
            color: $dark-gray
        }

        .breadcrumb a {
            color: $text-color
        }
    }

    .contacts-list-wrap {
        height: 103rem
    }

    .contacts-list {
        margin-top: 3.5rem;
    }

    .contacts-list__label {
        margin-bottom: 1rem;
    }

    .contacts-list__text {
        line-height: 1.1;
        padding-left: .7rem;
    }

    .contacts-list__item {
        margin-bottom: 2.1rem
    }

    .contacts-main .breadcrumbs {
        margin-top: .9rem;
    }

    .filter-cat-2 {
        padding: .9rem 1.5rem .8rem;
        background: #F7F7F7;
        box-shadow: 0px 4px 4px -2px #18274B14;

    }

    .section-head-inner_category {
        .h1 {
            font-size: 2.4rem;
        }

        .section-head__aside {
            padding-top: 0;
        }

        .breadcrumbs {
            margin-bottom: 1.5rem;
        }

        .main-block__download {
            margin-top: 2rem;
        }

        .btn-download, .btn-7 {
            width: 100%;
            max-width: 48rem
        }
    }

    .contacts-trade-form-wrap_distributors {
        margin-top: 0;
        padding-bottom: 0;
    }

    .contacts-tsearch-wrap {
        padding: .8rem 0;

        .input-search {
            height: 3.6rem;
            padding-left: 3.5rem;
        }

        .btn-search {
            width: 2rem;
            height: 2rem;
            color: #fff;
            font-size: 1.2rem;
            left: .9rem;
            top: .8rem;
        }
    }

    .contacts-representatives-wrap {
        padding: 2rem 0 0;
    }

    .filter-categories-2_2 {
        margin-top: 1.9rem;

        .filter-cat-2 {
            background: #fff;
            flex: 1 1 100%;
            min-width: 0;
            margin-right: 1.2rem;

            &.active,
            &:hover {
                background: $dark-bg
            }
        }
    }

    .distributors-tabs {

        padding: 0 0 2.9rem;

        .tab-content {
            padding-top: 0.7rem;
        }
    }

    .decor-17,
    .decor-18 {
        display: none;
    }

    .distributor__item {
        padding: 1.2rem 1.1rem .8rem;
        width: calc(50% - 2.4rem);
        min-height: 0
    }

    .distributor__title {
        font-size: 1.6rem;
        margin-bottom: .5rem;
    }

    .distributor__contatcts-item {
        margin: 1px 0 0;
    }

    .contacts-list__icon.icon-mail {
        font-size: 1.2rem;
    }

    .distributor {
        .contacts-list__details {
            padding-left: 0.4rem;
            min-height: 2.5rem
        }

        .contacts-list__text {
            line-height: 1.3;
        }

        .contacts-list__icon {
            margin-top: .2rem
        }
    }

    .distributor__link {
        margin-top: .4rem;
    }

    .section-agroservices .breadcrumbs {
        margin: 2.3rem 0 3rem;
    }

    .list-arrow ul li {
        padding-left: 3.8rem;
    }

    .list-arrow ul li:before {
        font-size: 1.7rem;
        top: 0;
    }

    .list-arrow ul li+li {
        margin-top: 2rem;
    }

    .section-head-inner_cagroservices-demo {
        margin-top: 0;
        padding: 4rem 0 2.7rem;

        .section-head {
            display: flex;
            flex-direction: column-reverse;
        }

        .section-head__title {
            width: 100%
        }

        .section-head__aside {
            text-align: center;
            width: 100%;
            justify-content: center;
            margin-bottom: 1.5rem;
        }
    }

    .section-agroservices-demo {
        padding: 2rem 0 3rem;
    }

    .agroservices-tabs .map-1 {
        margin: .8rem 0 0;
    }

    .section-head-inner_about {
        min-height: 0
    }

    .section-history {
        padding: 4rem 0 2rem;
    }

    .categories-list__item:hover .categories-list__bottom {
        transform: translateY(-1rem);
    }

    .section-head-inner_product .section-head__title {
        width: 100%;
    }

    .section-head-inner_results {
        .breadcrumbs {
            margin-bottom: 1.5rem;
        }
        .h1 {
            margin-bottom: .2rem
        }
        .section-subtext {
            max-width: 70%
        }
    }

    .section-head-inner_results .section-head__aside {
        padding-top: 3.6rem;
    }

    .search-form-inner .input-search {
        height: 4.8rem;
    }
    .search-form-inner {
        width: 100%;
            .reset-btn {
        margin-top: 1.4rem;
    }
    }
    .search-form-inner .btn-search {
        left: 1rem;
        top: .7rem;
    }

    .section-categories_search {
        padding: 4rem 0 0;
    }

    .categories-list__item:before {
        padding-top: 130%;
    }
    .search-list__title {
        font-size: 1.6rem;
    }
    .search-list__category {
        font-size: 1.2rem;
        margin-top: 1.3rem;
    }
    .search-list__text {
        margin-top: .9rem;
        line-height: 140%;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .search-list__item + .search-list__item {
        margin-top: 3.3rem;
    }
    .filter-categories_blog {
        margin-left: -2rem !important;
        margin-right: -2rem !important;
        padding: 0 2rem;
        display: flex !important;
                &::-webkit-scrollbar {
            height: .4rem;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $dark-bg;
            outline: $dark-bg;
        }
    }
}

@media screen and (max-width: 560px) {
    .representatives__item {
        display: block;
        border-radius: 1.2rem
    }

    .representatives__left {
        width: 100%;
        padding: 1.6rem 1.8rem 1.6rem;
        border-right-width: 1px;
        border-bottom-width: 0;
        border-radius: 1.2rem 1.2rem 0 0
    }

    .representatives__right {
        width: 100%;
        padding: 1.6rem 1.8rem 1.6rem;
        display: block;
    }

    .representatives .navs-slider_vertical {
        width: 100%;
        justify-content: center;
    }

    .distributor {
        margin: 0;
    }

    .distributor__item {
        margin: 0 0 1.2rem;
        width: 100%;
    }

    .section-head-inner_catalog {
        min-height: 0;
    }
    .filter-cat-2.selected, .filter-cat-2.active {
        pointer-events: none;
    }
    .filter-cat-2:hover {
        background: #F7F7F7;
        color: $text-color;
    }
}

@media screen and (min-width: 1366px) {
    .section-head-inner_about {
        .section-subtext {
            max-width: 59rem;
        }
    }
}

@media screen and (min-width: 1024px) {
    .section-head-inner_blog {
        .section-head__title {
            width: 66%;
            padding-right: 0;
        }
    }
}

@media screen and (min-width: 768px) {
    .link-accordion {
        display: none
    }
}

@media screen and (min-width: 1921px) {
    .decor-23, .decor-24 {
        display: none;
    }
}