.footer {
	position: relative;
	z-index: 3;
	overflow: hidden;
	font-size: 1.8rem;
	flex-shrink: 0;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: $dark-bg;

	.wrapper {
		&:after {
			display: none
		}
	}

	a {
		color: inherit;
	}


	.logo img {
		width: 29.1rem;
	}

	address {
		font-style: normal;
	}
}

.footer-top {
	padding: 9.9rem 0 7.3rem;
	background-size: auto 100%;
	background-position: 100% 100%;
	background-repeat: no-repeat;
}

.footer-bottom {
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 140%;
	padding: 1.5rem 0 1.6rem;
	border-top: 1px solid $dark-gray;

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	a {
		color: inherit;

		&:hover {
			color: $color-active;
		}
	}
}

.footer-info {
	margin-right: 2rem;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	font-size: 1.1rem
}

.footers-socials {
	display: flex;

	img {
		max-height: 2.4rem;
		transition: filter .6s ease;
	}

	.socials__link:hover img {

		filter: brightness(0) invert(1);
	}
}

.footer-navs {
	display: flex;
	width: 36%;
	margin: 0 -2rem;
	justify-content: space-around;
}

.footer-menu {
	padding: 0 2rem;

	ul {
		list-style: none;
		font-size: 1.8rem;

		li+li {
			margin-top: 1.8rem;
		}
	}

	a {
		border-bottom: 1px solid transparent;
		transition: all 0.7s ease;
		&:hover {
			border-bottom-color: #fff;
		}
	}
}

.footer-contacts {
	width: 35%;
	font-size: 1.8rem;

	&__col {
		width: 44%;

		&+& {
			width: 56%
		}
	}

	&__info {
		display: flex;
		margin: 0 -2rem;
	}

	&__item {
		display: flex;
		margin-bottom: 2.5rem;
		padding: 0 2rem;

		i {
			margin-right: 1.9rem;
			flex-shrink: 0;
			font-size: 120%;
			color: $color-active;
			&.icon-mail {
				font-size: 95%;	
			}
		}
	}
}

.footer-wrapper {
	display: flex;
	justify-content: space-between;
}

.footer-text {
	margin: 3.6rem 9% 0;
	font-size: 1.4rem;
	line-height: 1.6rem;
	color: #828282;
}

.footer-title {
	font-weight: bold;
	font-size: 2.4rem;
	margin-bottom: 2.4rem;
	text-transform: uppercase;
}

.footer-aside {
	padding-top: .4rem;
	width: 22.6%;
}


.footer-bottom {
	font-weight: 500
}

.footer-bottom .develop {
	color: $color-active;
}

.footer-label {
	color: #D1D1D1;
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 140%;
	margin-bottom: .4rem;
}


@media screen and (max-width: 1700px) {
	.footer-aside {
		width: 21.6%;
	}

	.footer-navs {
		width: 33%
	}

	.footer-contacts {
		width: 45.4%;
		padding-left: 2%
	}
}

@media screen and (max-width:1366px) {
	.footer-top {
		padding: 8.9rem 0 3.1rem;
	}
	.footer-aside {
        width: 16.6%;
    }
    .footer-navs {
        width: 35%;
    }
     .footer-contacts {
        width: 46.4%;
    }
}

@media screen and (max-width:1023px) {
	.footer-wrapper {
		flex-wrap: wrap;
	}

	.footer-navs {
		width: 78.4%;
	}

	.footer-contacts {
		margin-top: 3rem;
		width: 100%;
		padding-left: 0;
	}
}

@media screen and (max-width:767px) {
	.footer-top {
		padding: 3.6rem 0 2.3rem;
	}

	.footer-aside {
		width: 100%
	}

	.footer-navs {
		width: 100%;
		margin: 2.8rem 0 0;
		justify-content: flex-start;
	}

	.footer .logo img {
		width: 11.1rem;
	}

	.footer-menu {
		padding: 0;

		ul {
			font-size: 1.2rem;
			display: flex;
			margin: 0 -1.2rem;
			flex-wrap: wrap;
		}

		li {
			width: 50%;
			margin-bottom: .9rem;
			padding: 0 1.2rem;
		}

		ul li+li {
			margin-top: 0;
		}
	}

	.footer-contacts {
		display: none;
	}

	.footer-bottom .wrapper {
		flex-direction: column-reverse;
	}

	.footer-info {
		display: block;
		margin: 1.6rem 0 0;
		font-size: .8rem;
		width: 100%;
	}

	.link-btm {
		margin-top: .6rem;
		line-height: 134%;
	}

	.footer-bottom {
		padding: 1.8rem 0 5.6rem;
	}
	.politics {
		display: block;
	}
	.footer-divider-1, .footer-divider-3 {
		display: none;
	}
}