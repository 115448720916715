.main-wrapper {
    padding: 0;
    min-width: $min-width;
    width: 100%;
    position: relative;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 1
}
.main-wrapper-hidden {
    overflow: hidden;
}

.wrapper {
    min-width: $min-width;
    max-width: $max-width;
    padding-right: 8.5%;
    padding-left: 8.5%;
    margin: 0 auto;
    position: relative;
    width: 100%;
}

.wrapper-full {
    max-width: 100%;
    padding-right: 3.4rem;
    padding-left: 3.4rem;
}

footer {
    order: 3;
}

/* titles */
p {
    padding: .6rem 0;
}

h1,
.h1 {
    font-weight: 500;
    font-size: 4.8rem;
    line-height: 120%;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 3rem;
    span {
        color: $color1;
    }
}

.h1 {
    font-size: 5.2rem
}

h2,
.h2 {
    padding: 0;
    margin: 0 0 2.1rem;
    font-size: 4.8rem;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    letter-spacing: .02em;
}

.h2-sm {
    padding: 0;
    margin: 0 0 2.1rem;
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
}
.h2_1 {
    font-size: 5.2rem
}
h3,
.h3 {
    font-size: 3.2rem;
    line-height: 1.2;
    font-weight: 700;
    text-transform: uppercase;
    padding: 1rem 0 2rem;
    text-align: center;
}

.h3 {
    padding: 0 0 3.3rem
}

.h2.animated:after {

    transform: scale(1);
}

.colored {
    color: $color1;
}
.colored-2 {
    color: $color-active;
}
.section-dark {
    background: $color-active;
    color: #fff;

    .h2,
    .h3,
    .h2-category {
        color: inherit;
    }

    .section-head .h2 {
        color: $text-color;
    }

    .slick-arrow {
        border-color: #fff;
        color: #fff;
    }
}

.web .section-dark .slick-arrow:hover {
    border-color: #fff;
    color: #fff;
    opacity: .8
}

.section-black {
    background: $dark-bg;
    color: #fff;

    .h2,
    .h3 {
        color: #fff;
    }

    .h2-category {
        color: $color-active;
    }

    &_revert {

        .h2,
        .h3 {
            color: $color-active;
        }

        .h2-category {
            color: #fff;
        }
    }

}


h4 {
    font-weight: 700;
    line-height: 1.4;
    text-transform: uppercase;
    padding-top: 2.5rem
}


/* text position */
.text-left {
    text-align: left !important
}

.text-center {
    text-align: center !important
}

.text-right {
    text-align: right !important
}

.nowrap {
    white-space: nowrap !important;
}

.center {
    text-align: center;
}


/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content {
    min-width: $min-width;
    text-align: left;
    width: 100%;
    order: 2;
    flex-grow: 1;
    position: relative;
}


@keyframes smoothbounceball {
    from {
        transform: translate3d(0, 0, 0);
    }

    30% {
        transform: translate3d(-2rem, 0, 0);
    }

    60% {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes smoothbounceball2 {
    from {
        transform: translate3d(0, 0, 0);
    }

    30% {
        transform: translate3d(2rem, 0, 0);
    }

    60% {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

.btn {
    border: 0;
    color: #fff;
    font-weight: 600;
    padding: 0 1.9rem;
    vertical-align: top;
    text-align: center;
    height: 5.6rem;
    line-height: 5.6rem;
    font-size: 1.8rem;
    background: $color-active;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background .5s ease, color .5s ease;
    background: $color-active;
    border-radius: 10rem;
    display: inline-flex;
    cursor: pointer;
    align-items:
        center;

    i {
        background: $color2;
        border-radius: 50%;
        margin-right: 1rem;
        width: 3.2rem;
        height: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: none;
        color: #fff;
    }

    &:disabled {
        pointer-events: none;
        background: #E3E3E3
    }
    &:hover{
        background: $color1;
        color: $text-color;
        i {
            color: #fff;
        }
    }
}

.btn-2 {
    border: 0;
    color: $text-color;
    font-weight: 600;
    padding: 0 2.5rem;
    vertical-align: top;
    text-align: center;
    height: 4.9rem;
    line-height: 4.9rem;
    font-size: 1.8rem;
    background: rgba(255, 255, 255, 0.50);
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background .5s ease, color .5s ease;
    border-radius: 10rem;
    display: inline-flex;
    align-items: center;
    &:hover {
        background: $color1;
        color: $color2;
    }
}

.btn-3 {
    border: 0;
    color: $text-color;
    font-weight: 600;
    padding: 0 2.7rem;
    vertical-align: top;
    text-align: center;
    height: 4.9rem;
    line-height: 4.9rem;
    font-size: 1.8rem;
    background: #fff;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background .5s ease, color .5s ease;
    border-radius: 10rem;
    display: inline-flex;
    align-items:center;
    &:hover {
        background: $color1;
    }
}

.btn-4 {
    border: 0;
    color: $text-color;
    font-weight: 600;
    padding: 0 2.7rem;
    vertical-align: top;
    text-align: center;
    height: 4.9rem;
    line-height: 4.9rem;
    font-size: 1.8rem;
    background: $color1;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background .5s ease, color .5s ease;
    border-radius: 10rem;
    display: inline-flex;
    align-items:
        center;

    &:hover {
        color: #fff;
        background: $color-active
    }
}

.btn-5 {
    border: 0;
    color: $text-color;
    font-weight: 600;
    padding: 0 2.7rem;
    vertical-align: top;
    text-align: center;
    height: 4.9rem;
    line-height: 4.9rem;
    font-size: 1.8rem;
    background: #fff;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background .5s ease, color .5s ease;
    border-radius: 10rem;
    display: inline-flex;
    align-items: center;
    box-shadow: 0px .2rem .4rem -.2rem rgba(24, 39, 75, 0.12), 0px .4rem .4rem -.2rem rgba(24, 39, 75, 0.08);

    &:hover {
        color: #fff;
        background: $color-active;

    }

    i {
        margin-right: 1rem;


    }
}
.btn-7 {
    color: #fff;
    background: $color2;
    i {
        background: $color-active
    }
    &:hover {
        background: $color-active;
        i {
            background: $color2
        }
    }
}

.btn-8 {
    border: 1px solid #72AD43;
    background: transparent;
    color:#72AD43;
    &:hover {
        color: #fff;
        background: #72AD43;
    }
}
.load-ic {
    font-size: 1.8rem;
    width: 2.4rem;
    height: 2.4rem;
    background: $color-active;
    transition: background .5s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%
}

.btn-5:hover .load-ic {
    background: $color2
}
.btn-6 {
    border: 0;
    padding: 0 2.4rem 0 1.6rem;
    background: #fff;
    font-size: 1.8rem;
    font-weight: 600;
    height: 5.6rem;
    line-height:5.6rem;
     border-radius: 10rem;
    text-transform: uppercase;
    display:inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px -2px #18274B1F;
    transition: box-shadow .5s ease, color .5s ease;

    i {
        width: 2.8rem;
        height: 2.8rem;
        margin-right: 1.3rem;
        background: $color2;
        color: #fff;
        border-radius: 50%;
        font-size: 75%;
        display:inline-flex;
        align-items: center;
        justify-content: center;
    }
    &:hover  {
        color: $color2;
        box-shadow: 0px 2px 14px -2px #18274B1F;
    }
}
.icon-global {
    line-height: 1;
    &:before {
        display: inline-block;
        vertical-align: top;
    }
}
[type="text"],
[type="tel"],
[type="email"],
[type="password"],
textarea {

    height: 4.4rem;
    position: relative;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 0;
    color: $dark-gray;
    background: transparent;
    font-size: 2rem;
    z-index: 1;
    padding: 0;
    font-weight: 400;
    width: 100%;
    z-index: 0;

    &.error {

        border-color: #FF5050;
    }

    &:focus {
        border-color: #000
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        background: #fff;
        border: 1px solid #fff;
        -webkit-text-fill-color: #000;
        -webkit-box-shadow: inset 0 0 10rem 4rem #fff;
        transition: none;
    }
}


::-webkit-input-placeholder {
    color: $placeholder;
}

::-moz-placeholder {
    color: $placeholder;

}

:-moz-placeholder {
    color: $placeholder;
}

:-ms-input-placeholder {
    color: $placeholder;
}

textarea {
    padding-top: 1.6rem;
    border-width: .2rem;
    width: 100%;
    height: 11.2rem
}

label.error {
    color: #FF5050;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.9rem;
    display: block;
}

.box-field {
    position: relative;
    margin-bottom: 3.5rem;

    &__input {
        position: relative;

        label.error {
            position: absolute;
            left: 0;
            top: 100%;
        }
    }
}

.form-checkbox {
    margin: 4.7rem 0 2.8rem
}


.scrollDisabled {
    overflow: hidden;
    position: fixed !important;
    margin-top: 0;
    width: 100%;
    z-index: 0;
}

.scrollDisabled.web,
.scrollDisabled.web.compensate-for-scrollbar {
    padding-right: 1.7rem
}

.scrollDisabled .main-wrapper {
    overflow: visible;
}

.hide {
    display: none;
}

.color-decor {
    color: $color1;
}


.decor-wrap {
    overflow: hidden;
    height: 21rem;
    z-index: 1;
    width: 100%;
    position: relative;
    margin-top: 8rem;

    &_2 {
        margin-top: 11rem;
    }
}


.socials {
    &__item {
        margin-right: 1.4rem;
        display: inline-block;
        img {
                width: 2.4rem
        }
    }
}

.checkbox-el {
    font-weight: 500;

    label {
        position: relative;
        cursor: pointer;
    }

    input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0
    }

    &__txt {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: $text-color;
        display: inline-flex;
        align-items: center;
    }

    .check {
        display: block;
        border: 1px solid $line;
        background: #FCFCFC;
        width: 3.3rem;
        height: 3.3rem;
        flex-shrink: 0;
        margin: 0 1.5rem 0 0;
        position: relative;
        border-radius: 0;

        &:after {
            position: absolute;
            left: -.2rem;
            content: '';
            top: 50%;
            margin-top: -1rem;
            width: 100%;
            height: 100%;
        }
    }

    input:checked~.checkbox-el__txt .check {
        background: #FFDA22;
        transition: border-color .2s ease, background .2s ease;
    }

    input:checked~.checkbox-el__txt .check:after {
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNyAxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTcgMTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCg0KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNS4zLDAuMyA3LjQsOC42IDEuNiwzLjcgMC40LDUuMyA3LjYsMTEuNCAxNi43LDEuNyAiLz4NCjwvc3ZnPg0K");
        background-size: 1.8rem 1.8rem;
        background-position: 64% 0%;
        background-repeat: no-repeat;
    }
}

.web {
    .checkbox-el__txt:hover:before {
        transition: border-color .4s ease;
        border-color: $color-active;
    }
}

.tab {
    position: relative;
    z-index: 0;
    height: 0;
    overflow: hidden;
    .tab-content {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity .5s ease;
    z-index: 0
}
}


.link-accordion {
    display: none;
}

.tab.shown {
    z-index: 1;
    height: auto;
    overflow: auto;
    .tab-content {
    height: auto;
    opacity: 1;
    z-index: 1;
}
}

.accordion-body {
    display: none;
}
.highlight{
    background: rgba(115, 172, 67, 0.4)

}
@media screen and (max-width: 1439px) {
    .wrapper {
        padding-right: 5%;
        padding-left: 5%;
}
}
@media screen and (max-width: 1365px) {
    .h1 {
        font-size: 5rem;
    }
}
@media screen and (max-width: 1280px) {
    h2, .h2 {
        font-size: 4.1rem;
    }
}
@media screen and (min-width: 1024px) {
    .tablet-show {
        display: none !important;
    }

}

@media screen and (max-width: 1023px) {

    .h1 {
        font-size: 4.2rem;
    }

    .wrapper {
        padding-left: 3rem;
        padding-right: 3rem
    }

    .tablet-hide {
        display: none !important;
    }


    h2,
    .h2 {
        font-size: 3rem;
        margin: 0 0 2.9rem;
    }

    .h2 {
        margin-bottom: 1.2rem;


    }

    .h3 {
        text-align: center;
        font-size: 2.8rem;
        line-height: 3.7rem;
        margin-left: auto;
        margin-right: auto
    }

    .box-field {
        margin-bottom: 2.3rem;
    }

    [type=text],
    [type=tel],
    [type=email],
    [type="password"] {
        font-size: 1.6rem;
        padding: 0 2.3rem;
        height: 5.6rem
    }
    .btn, .btn-2, .btn-3, .btn-4, .btn-5, .btn-6 {
        font-size: 1.6rem
    }
}


@media screen and (max-width: 767px) {


    h1,
    .h1 {
        font-size: 2.6rem;
        margin-bottom: 1.5rem;
                letter-spacing: .018em;
    }

    h2,
    .h2 {
        font-size: 2.6rem;
    }
    .h2-sm {
        margin: 0 0 2.1rem;
        font-size:1.8rem;
    }
    h3, .h3 {
        font-size: 1.8rem
    }

    p {
        padding: .5em 0 .6rem;

    }


    [type=text],
    [type=tel],
    [type=email],
    [type="password"] {
        font-size: 1.4rem;
    }
    .wrapper {
        padding-left: 2rem;
        padding-right: 2rem
    }

    .mob-hide {
        display: none !important;
    }

    .box-field {
        margin-bottom: 0.4rem;
    }
    .label-placeholder {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }

    .form-control {
        font-size: 1.6rem
    }

    .filled .label-placeholder {
        font-size: 1.1rem
    }

    .btn, .btn-2, .btn-3, .btn-4, .btn-5 {
        font-size: 1.6rem;
        height: 3.8rem;
        line-height: 3.8rem;
    }

    .btn {

        i {
            margin-right: .8rem;
            width: 2rem;
            height: 2rem;
            font-size: 1.2rem
        }
    }

    .btn-3, .btn-5 {
        font-size: 1.4rem;
    }
    .btn-6 {
        border: 0;
        padding: 0 2.4rem 0 1rem;
        font-size: 1.4rem;
        height: 3.6rem;
        line-height:3.6rem;
        letter-spacing: .025em;
        i {
            height: 1.8rem;
            width:1.8rem;
            margin-right: 1rem
        }
    }
    .box-field__button {
        margin-top: 2rem;
        text-align: center;
        .btn {
            height: 4.5rem;
            line-height: 4.5rem;
                padding: 0 2.5rem;
        }
    }

    .link-accordion {
        display: block;
    }
.load-ic {
    font-size: 1.2rem;
    width: 2rem;
    height: 2rem;
}
}

.tooltip-wrap {
    position: relative;
    margin-left: .8rem;
    font-size: 1.6rem;
    display: inline-block;

    &:hover {
        z-index: 10000;

        .tooltip-text {
            display: block;
        }
    }
}

.tooltip-icon {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    background: #FFDA22;
    line-height: 2.4rem;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}

.tooltip-text {
    position: absolute;
    z-index: 1;
    display: none;
    left: 0;
    top: 100%;
    padding: 1rem;
    width: 15rem;
    background: #fff;
    border: 1px solid $line
}

@media screen and (min-width: 768px) {
    .mob-show {
        display: none !important;
    }
}